import React from "react";
import { Grid } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import { useEffect } from "react";
import ApiClient from "api/ApiClient";
import { groupBy } from "lodash";
import { useState } from "react";
import { getDateOfWeek } from "utils/helpers";
import dayjs from "dayjs";
import { NumberFormatter } from "utils/helpers";
import MDTypography from "components/MDTypography";
import { PRODUCTORDERING } from "utils/constants";
import { useSearchParams } from "react-router-dom";

export default function DataTables({
  dateRange,
  clientIdDescription,
  granularity,
  companyName,
  defaultDateRange,
}) {
  const apiClient = ApiClient;
  const [tableColumns, setTableColumns] = useState(null);
  const [tableRows, setTableRows] = useState(null);
  const [grandTotalRow, setGrandTotalRow] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const isSelectedRange =
    searchParams.get("defaultRange") === "true" ? false : true;

  useEffect(() => {
    if (!defaultDateRange) {
      return;
    }
    if (!companyName) {
      return;
    }

    const clientIdMappings = {
      all: "",
      dev: "Dev",
      main: "Main",
    };
    const granularityMappings = {
      monthly: "Month",
      weekly: "Week",
      daily: "Day",
    };
    let clientId = clientIdMappings[clientIdDescription];
    let gran = granularityMappings[granularity];

    apiClient.apiA
      .getActivityTypeFilterAll(
        dateRange[0],
        dateRange[1],
        gran,
        clientId,
        companyName
      )
      .then((res) => {
        // Group by date
        let groupedData = groupBy(res, (res) => res.tdate);
        // Convert to tuple array [[date, data]]
        let products = Object.keys(groupBy(res, (res) => res.ActivityType));
        let grandTotalRow = {};
        products.sort(
          (a, b) => PRODUCTORDERING.indexOf(a) - PRODUCTORDERING.indexOf(b)
        );
        products.forEach((p) => {
          grandTotalRow[p] = 0;
        });
        grandTotalRow["Grand Total"] = 0;
        // Add the main column, which is date The label on the table will be based on the granularity filter.
        let tableColumns = [
          {
            Header: gran === "Week" ? "Start of Week" : gran,
            accessor: "Date",
            width: "25%",
          },
        ];
        // Add a column for each product
        products.forEach((product) => {
          tableColumns.push({ Header: product, accessor: product });
        });
        tableColumns.push({ Header: "Grand Total", accessor: "Grand Total" });
        setTableColumns(tableColumns);
        // Create the rows - [{date: Date, <Activity1Name>: Number, <Activity2Name>: Number, .... }]
        const dates = Object.keys(groupedData);
        const processedData = [];
        dates.forEach((date) => {
          const row = {};
          let fdate = date;
          if (gran === "Week") {
            fdate = dayjs(
              getDateOfWeek(
                date.substring(date.indexOf("-") + 1),
                date.substr(0, date.indexOf("-"))
              )
            ).format("YYYY-MM-DD");
          }
          if (gran === "Month") {
            fdate = dayjs(fdate).format("YYYY-MM");
          }
          if (gran === "Day") {
            fdate = dayjs(fdate).format("YYYY-MM-DD");
          }
          row["Date"] = fdate;
          let rowTotal = 0;
          groupedData[date].forEach((product) => {
            row[`${product.ActivityType}`] = NumberFormatter(product.Total);
            rowTotal += Number(product.Total);
            grandTotalRow[`${product.ActivityType}`] += Number(product.Total);
          });
          grandTotalRow["Grand Total"] += rowTotal;
          row["Grand Total"] = NumberFormatter(rowTotal);
          processedData.push(row);
        });
        setGrandTotalRow(grandTotalRow);
        processedData.sort((a, b) =>
          dayjs(a.Date).isAfter(dayjs(b.Date)) ? 1 : -1
        );
        setTableRows(processedData);
      });
  }, [searchParams, isSelectedRange, defaultDateRange]);

  return (
    <>
      <Grid>
        {tableRows && tableColumns && grandTotalRow && (
          <DataTable
            table={{
              columns: tableColumns,
              rows: tableRows,
            }}
            title={<MDTypography variant="h6">Data Tables</MDTypography>}
            grandTotalRow={grandTotalRow}
          />
        )}
      </Grid>
    </>
  );
}
