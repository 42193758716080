/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Doughnut } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// StackedDoughnutChart configurations
import configs from "examples/Charts/DoughnutCharts/StackedDoughnutCharts/configs";

function StackedDoughnutChart({ title, description, height, chart, percentage, style }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {}, chart.cutout);

  const renderChart = (
    <MDBox py={2} pr={2} pl={2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 0 : 0} pt={description ? 0 : 0}>
          <MDBox mt={0}>
            <MDBox mb={3} mt={0}>
              <MDTypography component="div" variant="button" color="text" sx={{ textDecoration: "underline" }}>
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          <MDBox height={height}>
              <div className="absolute top-1/2 right-1/2 font-bold text-xl translate-x-1/2 translate-y-1/4">
                {percentage}
              </div>
              <Doughnut data={data} options={options} />
          </MDBox>
        ),
        [chart, height]
      )}
    </MDBox>
  );

  return title || description ? <Card style={style}>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of StackedDoughnutChart
StackedDoughnutChart.defaultProps = {
  title: "",
  description: "",
  height: "19.125rem",
  style: {}
};

// Typechecking props for the StackedDoughnutChart
StackedDoughnutChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
  style: PropTypes.object
};

export default StackedDoughnutChart;
