export default class EmissionsAPIClient {
  constructor(http, baseURL) {
    this.http = http;
    this.baseURL = baseURL;
  }

  async getEmissions({ clientId, deliveries, vehicleType, averageDistance }) {
    const result = await this.http.post(
      `${this.baseURL}/showcaseV4.php`,
      {
        cmd: "ghgemissions",
        ClientID: clientId,
        Deliveries: deliveries,
        VehicleType: vehicleType,
        AverageDistance: averageDistance,
      },
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return result.data;
  }
}
