export default class ProportionApiClient {
  constructor(http, baseURL) {
    this.http = http;
    this.baseURL = baseURL;
  }
  async getContractUsage(companyName) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIH.php?cmd=getUsage1&CompanyName=${companyName}`
    );
    return result.data.find(Boolean);
  }
  async getDataUsage(companyName) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIH.php?cmd=getUsage2&CompanyName=${companyName}`
    );
    return result.data.find(Boolean);
  }
}
