import React from "react";
import { useEffect } from "react";
import PostTagStatisticsCard from "examples/Cards/StatisticsCards/PostTagStatisticsCard";
import { Grid, Typography } from "@mui/material";
import ApiClient from "api/ApiClient";
import { useState } from "react";
import dayjs from "dayjs";
import { NumberFormatter } from "utils/helpers";
import { useSelector } from "react-redux";
import { authState } from "store/authSlice";
import PostTagDropdownSelect from "components/DropdownSelect/PostTagDropdownSelect";
import { getDaysArray } from "./hooks/useGetDays";
import _ from "lodash";
import CustomToolTip from "components/CustomToolTip";
import { CONTRACT_USAGE_CARD_OPTIONS } from "./constants";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ROLES } from "utils/constants";

export default function SummaryCards({
  companyName,
  dateRange,
  clientIdDescription,
}) {
  const apiClient = ApiClient;
  const auth = useSelector(authState);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [contractDetails, setContractDetails] = useState([]);
  const [changeLast7Days, setChangeLast7Days] = useState(null);
  const [usageLast7Days, setUsageLast7Days] = useState(null);
  const [changeLast30Days, setChangeLast30Days] = useState(null);
  const [usageLast30Days, setUsageLast30Days] = useState(null);
  const [contractCard, setContractCard] = useState(null);
  const [labelDefinitions, setLabelDefinitions] = useState(null);
  const [summaryDataQuality, setSummaryDataQuality] = useState(null);
  const [dataQualitySelector, setDataQualitySelector] = useState("Verified");
  const [contractUsageSelector, setContractUsageSelector] = useState(
    CONTRACT_USAGE_CARD_OPTIONS[0]
  );
  const isSelectedRange =
    searchParams.get("defaultRange") === "true" ? false : true;

  useEffect(() => {
    ApiClient.definitions.getLabelDefinitions().then((res) => {
      let labels = res?.filter((item) => {
        return ["Verified", "Unverified", "Unverified Non-Findable"].includes(
          item["Label"]
        );
      });
      labels = _.groupBy(labels, (label) => label["Label"]);
      setLabelDefinitions(labels);
    });
  }, []);

  useEffect(() => {
    if (!contractDetails) {
      return;
    }
    let interval = CONTRACT_USAGE_CARD_OPTIONS.indexOf(contractUsageSelector);
    let clientId = clientIdDescription === "all" ? "" : clientIdDescription;
    clientId = clientId
      ? clientId.charAt(0).toUpperCase() + clientId.slice(1).toLowerCase()
      : clientId;

    Promise.all([
      apiClient.rawTotals.getTotalsByInterval({ interval, companyName }),
      apiClient.rawTotals.getTotals({
        from: dateRange[0],
        to: dateRange[1],
        filter: clientId,
        granularity: null,
        companyName,
      }),
    ]).then(([rawTotals, dataQualityTotals]) => {
      setContractCard({
        total: rawTotals[0].Total,
        startDate: rawTotals[1].ContractStartDate,
        limit: rawTotals[2].previous_year_limit,
      });
      // Set data quality summary card
      let summary = { Verified: 0, Unverified: 0, "Non-Findable": 0 };
      summary["Verified"] = Number(
        dataQualityTotals[0]["% PostTag Verified"]
      ).toFixed(2);
      summary["Unverified"] = Number(
        dataQualityTotals[0]["% Unverified"]
      ).toFixed(2);
      summary["Unverified Non-Findable"] = Number(
        dataQualityTotals[0]["% Unverified Non-Findable"]
      ).toFixed(2);
      setSummaryDataQuality(summary);
    });
  }, [
    contractDetails,
    companyName,
    contractUsageSelector,
    location.search,
    contractUsageSelector,
  ]);

  const [id, setId] = useState(
    auth.user.role === ROLES.ADMIN ? null : auth.user.clientId
  );
  useEffect(() => {
    if (auth.user.role !== ROLES.ADMIN) {
      apiClient.company.getContracts(id).then((contractData) => {
        setContractDetails(contractData[0]);
      });
    }
    apiClient.company.getClientId(companyName).then((res) => {
      apiClient.company.getContracts(res[0]?.ClientID).then((contractData) => {
        setContractDetails(contractData[0]);
      });
    });
  }, [companyName]);

  useEffect(() => {
    const from = dayjs().subtract(60, "day").format("YYYY-MM-DD");
    const to = dayjs().format("YYYY-MM-DD");

    Promise.all([
      apiClient.rawTotals.getTotals({
        from,
        to,
        filter: "",
        granularity: "Day",
        companyName,
      }),
      apiClient.apiH.getContractComparisons(companyName),
      apiClient.rawTotals.getTotals({
        from: searchParams.get("from"),
        to: searchParams.get("to"),
        filter: "",
        granularity: null,
        companyName,
      }),
    ]).then(([rawTotals, contractComparisons, dataQualityTotals]) => {
      // Fill in missing dates and sort
      let expectedDates = getDaysArray(from, to);
      let resDates = {};
      for (let index = 0; index < rawTotals.length; index++) {
        const element = rawTotals[index];
        resDates[dayjs(element.tdate).format("YYYY-MM-DD")] = index;
      }
      expectedDates.forEach((date) => {
        if (!Object.keys(resDates).includes(date)) {
          rawTotals.push({ tdate: date, Total: 0 });
        }
      });
      rawTotals.sort(function (a, b) {
        return new Date(a.tdate) - new Date(b.tdate);
      });

      // Set current weeks totals and the change since last week
      const currWeekData = rawTotals.slice(
        rawTotals.length - 7,
        rawTotals.length
      );

      const sumCurrWeekTotals = currWeekData.reduce((accumulator, object) => {
        return accumulator + Number(object.Total);
      }, 0);

      setChangeLast7Days(Number(contractComparisons[0]["last7Days"]) ?? "");
      setUsageLast7Days(sumCurrWeekTotals);

      // Set current months totals and the change since last month
      const currMonthData = rawTotals.slice(
        rawTotals.length - 30,
        rawTotals.length
      );
      const prevMonthData = rawTotals.slice(
        rawTotals.length - 60,
        rawTotals.length - 30
      );

      const sumCurrMonthTotals = currMonthData.reduce((accumulator, object) => {
        return accumulator + Number(object.Total);
      }, 0);

      setChangeLast30Days(Number(contractComparisons[1]["last30Days"]) ?? "");
      setUsageLast30Days(sumCurrMonthTotals);
    });
  }, [companyName, searchParams.get("to"), searchParams.get("from")]);

  return (
    <Grid container spacing={3} columns={12}>
      <Grid item md={6} xs={12} xl={3} display="flex">
        <PostTagStatisticsCard
          dropdown={{
            setSelected: (e) => setContractUsageSelector(e),
            items: CONTRACT_USAGE_CARD_OPTIONS,
            selected: contractUsageSelector,
            fullWidth: false,
          }}
          count={
            <span
              className={`${
                Number(contractCard?.total) > Number(contractCard?.limit) &&
                "text-success"
              }`}
            >
              {contractCard?.total
                ? NumberFormatter(contractCard?.total)
                : "No data"}
            </span>
          }
          percentage={{
            label: `Contract Term Start Date: ${
              contractCard?.startDate
                ? dayjs(contractCard?.startDate).format("DD MMMM YYYY")
                : "No Data"
            }`,
          }}
        >
          <span className={`flex justify-end text-right text-secondary pt-4`}>
            Contract Limit:{" "}
            {contractCard?.limit
              ? NumberFormatter(contractCard.limit)
              : "No Data"}
          </span>
        </PostTagStatisticsCard>
      </Grid>
      <Grid item md={6} xs={12} xl={3} display="flex">
        <PostTagStatisticsCard
          title="Last 7 days"
          count={NumberFormatter(usageLast7Days ?? "")}
          percentage={{
            color: changeLast7Days > 0 ? "success" : "error",
            value: `${changeLast7Days > 0 ? "+" : ""}${changeLast7Days}%`,
            label: `${
              changeLast7Days > 0 ? "increase" : "decrease"
            } from contract daily average`,
          }}
        />
      </Grid>
      <Grid item md={6} xs={12} xl={3} display="flex">
        <PostTagStatisticsCard
          title="Last 30 days"
          count={NumberFormatter(usageLast30Days ?? "")}
          percentage={{
            color: changeLast30Days > 0 ? "success" : "error",
            value: `${changeLast30Days > 0 ? "+" : ""}${changeLast30Days}%`,
            label: `${
              changeLast30Days > 0 ? "increase" : "decrease"
            } from contract daily average`,
          }}
        />
      </Grid>
      <Grid item md={6} xs={12} xl={3} display="flex" width="100%">
        <PostTagStatisticsCard
          title={
            <PostTagDropdownSelect
              fullWidth={false}
              style={{
                "& fieldset": {
                  border: "none",
                  borderWidth: 0,
                },
              }}
              menuItems={["Verified", "Unverified", "Unverified Non-Findable"]}
              value={dataQualitySelector}
              setValue={setDataQualitySelector}
            />
          }
          count={
            summaryDataQuality &&
            `${NumberFormatter(summaryDataQuality[dataQualitySelector] ?? "")}%`
          }
          percentage={{
            label: (
              <div className="flex gap-x-2 items-center">
                <span>Definitions</span>
                <CustomToolTip>
                  <Typography
                    variant="p"
                    textAlign={"left"}
                    fontWeight="bold"
                    color="inherit"
                  >
                    {labelDefinitions &&
                      (labelDefinitions[dataQualitySelector][0]["Label"] ?? "")}
                  </Typography>
                  <br />
                  {labelDefinitions &&
                    (labelDefinitions[dataQualitySelector][0]["Definitions"] ??
                      "")}
                </CustomToolTip>
              </div>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}
