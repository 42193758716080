import * as React from "react";
import { useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function PostTagDropdownSelect({
  menuItems,
  value,
  setValue,
  defaultValue = null,
  fullWidth = true,
  style,
}) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (!menuItems.includes(value) && defaultValue) {
      setValue(defaultValue);
      return;
    }
  }, [value]);

  return (
    <FormControl fullWidth={fullWidth}>
      <Select
        className="custom_select_dropdown"
        sx={{
          color: "#000000",
          padding: "0 0.75rem",
          cursor: "pointer",
          textTransform: "capitalize",
          fontWeight: "300",
          ...style,
        }}
        IconComponent={ExpandMoreIcon}
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
        defaultValue={defaultValue}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            value={item}
            style={{ textTransform: "capitalize" }}
          >
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
