export default function DistanceRow({ label, text }) {
  return (
    <div className="flex lg:flex-col xl:flex-row">
      <div className="bg-primary p-5 w-full flex items-center text-center rounded-md text-white">
        {label}
      </div>
      <div className="bg-white flex-col text-black w-full p-5 rounded-md font-semibold">
        {text}
      </div>
    </div>
  );
}
