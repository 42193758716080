export default class ServicesApiClient {
  constructor(http, baseURL) {
    this.http = http;
    this.baseURL = baseURL;
  }
  async getMonthlyTotal({ from, to, companyName }) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIC.php?cmd=gettotalsbymonth&CompanyName=${companyName}&DateStart=${from}&DateEnd=${to}`
    );
    return result.data;
  }

  async getWeeklyTotal({ from, to, companyName }) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIC.php?cmd=gettotalsbyweek&CompanyName=${companyName}&DateStart=${from}&DateEnd=${to}`
    );
    return result.data;
  }

  async getDailyTotal({ from, to, companyName }) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIC.php?cmd=gettotalsbyday&CompanyName=${companyName}&DateStart=${from}&DateEnd=${to}`
    );
    return result.data;
  }
}
