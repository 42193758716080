import React from "react";
import PropTypes from "prop-types";
import { Card } from "@mui/material";

export default function EmissionCard({ title, stats }) {
  return (
    <Card className="w-full border border-success ">
      <div className="w-full flex flex-col items-center text-center h-full border-4 border-success py-2 px-2 lg:px-6 rounded-lg">
        <h1 className="pb-5 font-bold h-14 lg:h-auto">{title}</h1>
        <div className="flex flex-col gap-y-2 lg:gap-y-4">
          {stats?.map((stat, idx) => {
            return (
              <div className="flex flex-col" key={idx}>
                <h1 className="text-success text-lg font-bold lg:text-3xl">
                  {stat.number}{" "}
                </h1>
                <p className="lg:text-lg text-secondary font-semibold">
                  {stat.unit} {stat.time}
                </p>
              </div>
            );
          })}
        </div>
        <p className="mt-auto text-secondary lg:text-sm text-xs pt-5">
          (Based on 350 days)
        </p>
      </div>
    </Card>
  );
}

EmissionCard.propTypes = {
  title: PropTypes.string,
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.string,
      unit: PropTypes.string,
    })
  ),
};
