import React, { useState } from "react";
import { Icon } from "@iconify/react";
import PostTagLogo from "../../assets/images/posttag-logo.png";
import { Box, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOut } from "store/authSlice";

const SidebarItem = ({
  icon,
  title,
  onSelect,
  active,
  subItems = [],
  onRef,
}) => {
  const [activeItem, setActiveItem] = useState(subItems[0]?.name ?? null);
  return (
    <div className="">
      <button
        onClick={() => onSelect(title)}
        className={`pl-5 py-4 rounded-[4px] w-[218px] flex gap-x-5 items-center ${
          active && "bg-[#00A99D]"
        }`}
      >
        <Icon icon={icon} />
        <span>{title}</span>
      </button>
      <div className="pl-5 transition-all ease-in-out">
        {subItems &&
          active &&
          subItems.length > 0 &&
          subItems?.map((item, idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  onRef(item);
                  setActiveItem(item?.name);
                }}
                className={`pl-5 py-4 rounded-[4px] w-[218px] opacity-50 flex gap-x-5 items-center ${
                  item?.name === activeItem && "opacity-100"
                }`}
              >
                <Icon icon={item?.icon} />
                <span>{item?.name}</span>
              </button>
            );
          })}
      </div>
    </div>
  );
};

export default function Sidebar({ sidebarItems }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Box
      className="flex flex-col gap-y-4 relative"
      sx={{ marginTop: "10px", width: "250px", position: "sticky", top: 0 }}
    >
      <img src={PostTagLogo} className="h-[80px] mx-auto" alt="" />
      <div className="bg-primary sticky w-full top-10 rounded-3xl pl-2 pr-6 pt-12 pb-3 max-w-250px h-[84vh] flex flex-col">
        <div className="text-white">
          {sidebarItems &&
            sidebarItems.length > 0 &&
            sidebarItems?.map((sidebarItem, idx) => {
              const { title, icon, onSelect, subItems } = sidebarItem;
              return (
                <SidebarItem
                  active={location.pathname.includes(title.toLowerCase())}
                  title={title}
                  onSelect={onSelect}
                  subItems={subItems}
                  onRef={(subItem) => {
                    subItem.ref.current.scrollIntoView(true, {
                      behavior: "smooth",
                    });
                  }}
                  icon={icon}
                  key={idx}
                />
              );
            })}
        </div>
        <div className="mt-auto text-sm ml-4 gap-y-2 flex flex-col items-center justify-center text-white">
          <Button
            sx={{ margin: "0.25rem", marginBottom: "3px", marginTop: "auto" }}
            color="white"
            variant="contained"
            className="w-full"
            onClick={() => {
              dispatch(logOut());
              navigate("/login");
            }}
          >
            Log Out
          </Button>
          <a href="http://posttag.com/privacy-policy/" className="underline">
            Privacy policy
          </a>
        </div>
      </div>
    </Box>
  );
}
