import axios from "axios";
import config from "config";
import CompanyApiClient from "./CompanyApiClient";
import NonFindableCountsApiClient from "./NonFindableCountsApiClient";
import ProductsApiClient from "./ProductsApiClient";
import ProportionApiClient from "./ProportionApiClient";
import RawTotalsApiClient from "./RawTotalsApiClient";
import ServicesApiClient from "./ServicesApiClient";
import MapComparisonApiClient from "./MapComparisonApiClient";
import TotalCountsApiClient from "./TotalCountsApiClient";
import APIA from "./APIA";
import APIH from "./APIH";
import AuthApiClient from "./AuthApiClient";
import { toast } from "react-toastify";
import DefinitionsApiClient from "./DefinitionsApiClient";
import ShowcaseApiClient from "./ShowcaseApiClient";
import CustomerOverviewApiClient from "./CustomersOverviewApiClient";
import EmissionsAPIClient from "./EmissionsAPIClient";
import { POSTTAG_ERRORS } from "utils/errors";
import { getStorage } from "utils/storage";

const { servicesAPI } = config;

const http = axios.create({
  withCredentials: true,
  headers: {
    "content-type": "application/x-www-form-urlencoded",
  },
});

http.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${getStorage("access_token")}`;
    return config;
  },
  (error) => {
    toast.error(error.message);
    toast.clearWaitingQueue();
  }
);

http.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error?.response?.data?.status === 401 &&
      error?.response?.data?.message.includes(
        POSTTAG_ERRORS.ACCESS_TOKEN_EXPIRED
      )
    ) {
      window.location.replace("/login?expired");
      localStorage.clear();
    }
    toast.error(error.message);
    toast.clearWaitingQueue();
  }
);

export default class ApiClient {
  static totalCount = new TotalCountsApiClient(http, servicesAPI);
  static company = new CompanyApiClient(http, servicesAPI);
  static proportions = new ProportionApiClient(http, servicesAPI);
  static products = new ProductsApiClient(http, servicesAPI);
  static services = new ServicesApiClient(http, servicesAPI);
  static nonFindableCount = new NonFindableCountsApiClient(http, servicesAPI);
  static rawTotals = new RawTotalsApiClient(http, servicesAPI);
  static apiA = new APIA(http, servicesAPI);
  static apiH = new APIH(http, servicesAPI);
  static auth = new AuthApiClient(http, servicesAPI);
  static definitions = new DefinitionsApiClient(http, servicesAPI);
  static showcase = new ShowcaseApiClient(http, servicesAPI);
  static mapComparison = new MapComparisonApiClient(http, servicesAPI);
  static customersOverview = new CustomerOverviewApiClient(http, servicesAPI);
  static emissions = new EmissionsAPIClient(http, servicesAPI);
}
