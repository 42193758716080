import { Icon } from "@iconify/react";
import React, { useState } from "react";
import ApiClient from "api/ApiClient";
import { useSelector } from "react-redux";
import { authState } from "store/authSlice";
import { useMutation } from "react-query";

const SearchbarItem = ({ idx, address, onSelect = () => {} }) => {
  const formattedAddress = [
    address["Organisation"],
    address["Department"],
    address["Sub Building"],
    address["Number"],
    address["Building"],
    address["Sub Street"],
    address["Street"],
    address["PostTown"],
    address["Town"],
    address["Area"],
    address["Post Code"],
  ]
    .filter(Boolean)
    .join(", ");

  return (
    <li
      onClick={() => onSelect(address)}
      className="w-full my-2 flex items-center cursor-pointer shadow-sm rounded-md  -bottom-20 left-0"
    >
      <div className="bg-primary p-5 w-full max-w-[3.5rem] text-center rounded-md text-white">
        {idx}
      </div>
      <div className="bg-white text-black hover:bg-gray-200 w-full p-5 rounded-md font-semibold">
        {formattedAddress}
      </div>
    </li>
  );
};

const Searchbar = ({ onSelect = () => {}, isOpen = () => {} }) => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const { user } = useSelector(authState);
  const [search, setSearch] = useState("");
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const { mutate } = useMutation(
    (data) => ApiClient.showcase.getAddressInformation(data),
    {
      onSuccess: (data) => {
        const { data: res } = data;
        if (res.length === 0) {
          isOpen(false);
        }
        setAddressSuggestions(res);
      },
      onError: (error) => {
        setAddressSuggestions([]);
      },
    }
  );

  const onSubmit = (e) => {
    e.preventDefault();
    setSelectedAddress(null);
    mutate({
      clientId: user.id,
      input: search,
    });
  };

  return (
    <>
      <div className="w-full relative p-5 shadow-md rounded-lg gap-x-5 bg-primary text-white flex items-center">
        <p className="hidden sm:block font-bold items-end">Lookup:</p>
        <form className="flex w-full gap-x-5" onSubmit={onSubmit} action="">
          <input
            value={search}
            placeholder="Enter an address"
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            className="w-full text-black bg-white h-10 rounded-lg px-2"
          />
          <button type="submit">
            <Icon className="h-10 w-10" icon={"ph:magnifying-glass-bold"} />
          </button>
        </form>
      </div>
      {addressSuggestions &&
        addressSuggestions?.length > 0 &&
        !selectedAddress && (
          <ul className=" shadow-lg border overflow-hidden overflow-y-scroll">
            {isOpen(true)}
            {addressSuggestions.map((address, idx) => {
              return (
                <SearchbarItem
                  onSelect={() => {
                    setSelectedAddress(address);
                    onSelect(address);
                    isOpen(false);
                  }}
                  idx={idx + 1}
                  key={idx}
                  address={address}
                />
              );
            })}
          </ul>
        )}
    </>
  );
};

export default Searchbar;
