import React from 'react'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Info } from '@mui/icons-material'
import useWindowSize from 'hooks/useWindowSize';

const ToolTipStyling = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
});

export default function CustomToolTip({children}) {
  const {width} = useWindowSize();
  return (
    <ToolTipStyling maxWidth={width < 768 ? '350px' : '500px'} title={
        <React.Fragment>
            {children}
        </React.Fragment>
    } placement={width < 768 ? 'top' : 'right'} >
        <Info/>
    </ToolTipStyling>
  )
}
