import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import "./app.css";
import ProtectedRoute from "pages/routes/ProtectedRoute";
import AdminProtectedRoute from "pages/routes/AdminProtectedRoute";
import Login from "pages/Login";
import "react-toastify/dist/ReactToastify.css";
import Showcase from "pages/Showcase";
import Overview from "pages/Customers Overview";
import ESG from "pages/ESG/ESG";
import { authState } from "store/authSlice";
import { useSelector } from "react-redux";
import { PAGES } from "utils/constants";
import NotFound from "pages/Error/NotFound";

export default function App() {
  const { isLoggedIn, user } = useSelector(authState);
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      {isLoggedIn && user?.pages && user.pages.length > 0 && (
        <Route element={<ProtectedRoute />}>
          {user?.pages?.includes(PAGES["DASHBOARD"]) && (
            <Route path="/dashboard" element={<Dashboard />} />
          )}
          <Route element={<AdminProtectedRoute />}>
            {user?.pages?.includes(PAGES["SHOWCASE"]) && (
              <Route path="/showcase" element={<Showcase />} />
            )}
            {user?.pages?.includes(PAGES["OVERVIEW"]) && (
              <Route path="/overview" element={<Overview />} />
            )}
            {user?.pages?.includes(PAGES["ESG"]) && (
              <Route path="/esg" element={<ESG />} />
            )}
          </Route>
        </Route>
      )}
      <Route
        path="*"
        element={isLoggedIn ? <NotFound /> : <Navigate to="/login" />}
      />
    </Routes>
  );
}
