export default class ProductsApiClient {
  constructor(http, baseURL) {
    this.http = http;
    this.baseURL = baseURL;
  }

  async getTotals({ from, to, granularity, filter = "", companyName }) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIA.php?cmd=getActivityTypeFilterAll&CompanyName=${companyName}&DateStart=${from}&DateEnd=${to}&Filter=${filter}&Granularity=${granularity}`
    );
    return result.data;
  }

  async getClientWeeklyTotal(from, to, companyName) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIB.php?cmd=gettotalsbyweek&CompanyName=${companyName}&DateStart=${from}&DateEnd=${to}}`
    );
    return result.data;
  }

  async getQualityTrends({ from, to, granularity, filter = "", companyName }) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIJ.php?cmd=gettotalsfilter&CompanyName=${companyName}&DateStart=${from}&DateEnd=${to}&Filter=${filter}&Granularity=${granularity}`
    );
    return result.data;
  }
}
