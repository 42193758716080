export default class NonFindableCountsApiClient {
  constructor(http, baseURL) {
    this.http = http;
    this.baseURL = baseURL;
  }
  async getTotals({ to, from, granularity, filter = "", companyName }) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIG.php?cmd=getNonFindablesFilter&CompanyName=${companyName}&DateStart=${from}&DateEnd=${to}&Filter=${filter}&Granularity=${granularity}`
    );
    return result.data;
  }
}
