/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import SummaryTableCell from "examples/Tables/SummaryTable/SummaryTableCell";
import { TableCell } from "@mui/material";

function SummaryTable({ title, rows, shadow, rowHeaders, summaryRow }) {
  const renderTableCells = rows.map((row, key) => {
    const tableRows = [];
    const rowKey = `row-${key}`;

    Object.entries(row).map(([cellTitle, cellContent], index) =>
      Array.isArray(cellContent)
        ? tableRows.push(
            <SummaryTableCell
              key={index}
              title={cellTitle}
              content={cellContent[1]}
              image={cellContent[0]}
              noBorder={true}
              bold={index % 4 === 0}
            />
          )
        : tableRows.push(
            <SummaryTableCell
              key={index}
              title={cellTitle}
              content={cellContent}
              noBorder={true}
              bold={index % 4 === 0}
            />
          )
    );

    return <TableRow key={rowKey}>{tableRows}</TableRow>;
  });

  return (
    <TableContainer sx={{ height: "100%", boxShadow: !shadow && "none", padding: '10px' }}>
              {title ? (
        <div sx={{width: '100%'}}>
            {title}
          </div>
        ) : null}
      <Table sx={{height: '100%'}}>
        <TableBody>
            <TableRow>
            {
              rowHeaders.map((header, index) => 
              <TableCell key={index} sx={{paddingBottom: 0, paddingTop: 0}}>
                <MDTypography
                  variant="caption"
                  color="text"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                {header}
                </MDTypography>
              </TableCell>)
                }
            </TableRow>
          
          {useMemo(() => renderTableCells, [rows])}
          {summaryRow.length > 0 &&
          <TableRow>
          <TableCell sx={{ border: 0, borderTop: '1px solid #E0E0E0', paddingTop: 0 }}>
            <MDTypography
                textTransform="capitalize"
                fontWeight='bold' variant="caption" fontSize="small"
              >
              Summary
              </MDTypography>
            </TableCell>
          <TableCell sx={{ border: 0, borderTop: '1px solid #E0E0E0', paddingTop: 0 }}>              
            <MDTypography
                textTransform="capitalize"
                fontWeight='bold' variant="caption" fontSize="small"
              >
              {summaryRow[0]}
            </MDTypography>
          </TableCell>
          <TableCell sx={{ border: 0, borderTop: '1px solid #E0E0E0', paddingTop: 0 }}>              
            <MDTypography
                textTransform="capitalize"
                fontWeight='bold' variant="caption" fontSize="small"
              >
              {summaryRow[1]}
            </MDTypography>
          </TableCell>
          <TableCell sx={{ border: 0, borderTop: '1px solid #E0E0E0', paddingTop: 0 }}>              
            <MDTypography
                textTransform="capitalize"
                fontWeight='bold' variant="caption" fontSize="small"
              >
              {summaryRow[2]}
            </MDTypography>
          </TableCell>
          </TableRow>
          
          
          }
          
          
          </TableBody>
      </Table>
    </TableContainer>
  );
}

// Setting default values for the props of SummaryTable
SummaryTable.defaultProps = {
  title: "",
  rows: [{}],
  shadow: true,
  rowHeaders: [],
  summaryRow: []
};

// Typechecking props for the SummaryTable
SummaryTable.propTypes = {
  title: PropTypes.any,
  rows: PropTypes.arrayOf(PropTypes.object),
  shadow: PropTypes.bool,
  rowHeaders: PropTypes.array,
  summaryRow: PropTypes.array
};

export default SummaryTable;
