import React from "react";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import { useEffect } from "react";
import ApiClient from "api/ApiClient";
import { useState } from "react";
import { FormatAxis } from "utils/helpers";
import { useSearchParams } from "react-router-dom";
import { FormatData } from "utils/helpers";
import { FormatGranularity } from "utils/helpers";
import { useSelector } from "react-redux";
import { authState } from "store/authSlice";
import { NumberFormatter } from "utils/helpers";
import MDTypography from "components/MDTypography";

export default function UsageByService({
  companyName,
  dateRange,
  granularity,
}) {
  const apiClient = ApiClient;
  const auth = useSelector(authState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [services, setServices] = useState(null);
  const [axis, setAxis] = useState(null);
  const [data, setData] = useState(null);

  const totalServiceUsage = (service) => {
    if (!service.length) return;
    return NumberFormatter(
      service.reduce((acc, curr) => {
        return acc + Number(curr?.Total ?? 0);
      }, 0)
    );
  };

  const updateServices = (servicesFilters) => {
    Promise.all([
      apiClient.rawTotals.getTotals({
        from: dateRange[0],
        to: dateRange[1],
        granularity: FormatGranularity(granularity),
        filter: "Main",
        companyName: companyName,
      }),
      apiClient.rawTotals.getTotals({
        from: dateRange[0],
        to: dateRange[1],
        granularity: FormatGranularity(granularity),
        filter: "Dev",
        companyName: companyName,
      }),
    ]).then(([main, dev]) => {
      const s = {};
      if (servicesFilters.includes("Main")) {
        s["main"] = main;
      }
      if (servicesFilters.includes("Dev")) {
        s["dev"] = dev;
      }
      setServices(s);
      let formattedData = FormatData({
        rawData: s.main,
        returnDataType: (data) => {
          return data?.Total;
        },
        extractDate: (data) => {
          return data?.tdate;
        },
        granularity: granularity,
        from: dateRange[0],
        to: dateRange[1],
      });
      setData(formattedData);
      setAxis(FormatAxis(dateRange[0], dateRange[1], granularity));
    });
  };
  useEffect(() => {
    if (!companyName) {
      return;
    }
    setServices({ main: [], dev: null });
    apiClient.company
      .getServicesFilters(companyName, auth.user.role)
      .then((res) => {
        let availableFilters = res.map((obj) => obj.ClientIDDescription);
        updateServices(availableFilters);
      });

    return () => {
      setData([]);
      setServices([]);
      setAxis([]);
    };
  }, [searchParams]);

  return (
    <>
      <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-x-5 gap-y-5 xl:gap-y-0">
        <div className="w-full">
          {services && services.main && data && axis && (
            <VerticalBarChart
              class="w-full"
              icon={{ color: "info", component: "leaderboard" }}
              title={
                <div className="flex w-full items-center justify-between">
                  <div className="w-full">Main</div>
                  <div className="flex text-right flex-col gap-y-2">
                    <MDTypography
                      variant="button"
                      color="text"
                      textTransform="capitalize"
                    >
                      Total
                    </MDTypography>
                    <span className="font-bold">
                      {totalServiceUsage(services.main)}
                    </span>
                  </div>
                </div>
              }
              chart={{
                labels: axis,
                datasets: [
                  {
                    color: "dark",
                    data: data,
                  },
                ],
              }}
            />
          )}
        </div>
        <div className="w-full">
          {services && services.dev && (
            <VerticalBarChart
              class="w-full"
              icon={{ color: "info", component: "leaderboard" }}
              title={
                <div className="flex w-full items-center justify-between">
                  <div className="w-full">Dev</div>
                  <div className="flex text-right flex-col gap-y-2">
                    <MDTypography
                      variant="button"
                      color="text"
                      textTransform="capitalize"
                    >
                      Total
                    </MDTypography>
                    <span className="font-bold">
                      {totalServiceUsage(services.dev)}
                    </span>
                  </div>
                </div>
              }
              chart={{
                labels: FormatAxis(dateRange[0], dateRange[1], granularity),
                datasets: [
                  {
                    color: "dark",
                    data: FormatData({
                      rawData: services.dev,
                      returnDataType: (data) => {
                        return data?.Total;
                      },
                      extractDate: (data) => {
                        return data?.tdate;
                      },
                      granularity: granularity,
                      from: dateRange[0],
                      to: dateRange[1],
                    }),
                  },
                ],
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}
