import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { authState } from "store/authSlice";

const ProtectedRoute = () => {
  const auth = useSelector(authState);
  return auth && auth.user && auth.isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
