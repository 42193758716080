import dayjs from "dayjs";

function getDateXDaysAgo(numOfDays, date = new Date()) {
    const daysAgo = new Date(date.getTime());
  
    daysAgo.setDate(date.getDate() - numOfDays);
  
    return daysAgo;
}

const getDaysArray = function(start, end) {
    
    for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
        const date = dayjs(new Date(dt)).format('YYYY-MM-DD')
        arr.push(date);
    }
    return arr;
};

export { getDaysArray, getDateXDaysAgo }