/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";

// Material Dashboard 2 PRO React components
import MDInput from "components/MDInput";
import { useRef, useEffect, forwardRef, useImperativeHandle } from "react";

const MDDatePicker = forwardRef(({from, to, ...rest}, ref) => {
  const fromInstance = useRef(null);
  const toInstance = useRef(null);

  useImperativeHandle(ref, () => {
    return {
      fromRef: fromInstance.current,
      toRef: toInstance.current
    };
  })

  useEffect(() => {
    if (fromInstance.current) {
      fromInstance.current.flatpickr.jumpToDate(from.options.defaultDate[1]);
    }
  }, []);

  return (
    <div className="flex gap-x-2 w-full">
      <div className="flex-col flex w-full">
        <span className="text-secondary italic text-sm font-light">
          Start date
        </span>
        <Flatpickr
          ref={fromInstance}
          {...rest}
          onChange={(e) => from.onChange(e)}
          options={from.options}
          render={({ defaultValue }, ref) => (
            <MDInput
              {...from.input}
              sx={{ width: "100%" }}
              defaultValue={defaultValue}
              inputRef={ref}
            />
          )}
        />
      </div>
      <div className="flex flex-col w-full">
        <span className="text-secondary italic font-light text-sm">
          End date
        </span>
        <Flatpickr
          ref={toInstance}
          onChange={(e) => to.onChange(e)}
          {...rest}
          options={to.options}
          render={({ defaultValue }, ref) => (
            <MDInput
              {...to.input}
              sx={{ width: "100%" }}
              defaultValue={defaultValue}
              inputRef={ref}
            />
          )}
        />
      </div>
    </div>
  );
});

// Setting default values for the props of MDDatePicker
MDDatePicker.defaultProps = {
  input: {},
  options: {},
};

// Typechecking props for the MDDatePicker
MDDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.objectOf(PropTypes.any),
};

export default MDDatePicker;
