export default class ShowcaseApiClient {
  constructor(http, baseURL) {
    this.http = http;
    this.baseURL = baseURL;
  }

  getAddressInformation = async ({ clientId, input }) => {
    const result = await this.http.post(`${this.baseURL}/showcaseV4.php`, {
      cmd: "showcase",
      ClientID: clientId,
      Input: input,
    });
    return result.data;
  };
}
