import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import dayjs from "dayjs";
import { useEffect } from "react";
import BaseLayout from "layouts/GenericLayout";
import ClientOverviewFilterBar from "components/ClientOverviewFilterBar";
import ClientUsageDataTables from "components/CustomersOverview";

export default function CustomersOverview() {
  const [searchParams] = useSearchParams();
  const dateRange = [searchParams.get("from"), searchParams.get("to")];
  const granularity = searchParams.get("granularity");
  const clientIdDescription = searchParams.get("clientIdDescription");
  const companyName = searchParams.get("companyName");

  const [defaultDateRange, setDefaultDateRange] = useState(null);

  useEffect(() => {
    let defaultRange = [
      dayjs().subtract(30, "day").format("YYYY-MM-DD"),
      dayjs().format("YYYY-MM-DD"),
    ];
    setDefaultDateRange(defaultRange);
  }, [companyName]);

  return (
    <BaseLayout>
      <div className="w-full overflow-hidden flex flex-col gap-y-10">
        <ClientOverviewFilterBar />
        {dateRange &&
          granularity &&
          clientIdDescription &&
          companyName &&
          defaultDateRange && (
            <>
              <ClientUsageDataTables
                defaultDateRange={defaultDateRange}
                companyName={companyName}
                clientIdDescription={clientIdDescription}
                granularity={granularity}
                dateRange={dateRange}
              />
            </>
          )}
      </div>
    </BaseLayout>
  );
}
