export default class APIA {
  constructor(http, baseURL) {
    this.http = http;
    this.baseURL = baseURL;
  }

  async getActivityTypeFilter(from, to, granularity, clientId, companyName) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIA.php?cmd=getActivityTypeFilter&CompanyName=${companyName}&DateStart=${from}&DateEnd=${to}&Granularity=${granularity}&Filter=${clientId}`
    );
    return result.data;
  }

  async getActivityTypeFilterAll(from, to, granularity, clientId, companyName) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIA.php?cmd=getActivityTypeFilterAll&CompanyName=${companyName}&DateStart=${from}&DateEnd=${to}&Granularity=${granularity}&Filter=${clientId}`
    );
    return result.data;
  }
}
