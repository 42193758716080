import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {
      id: null,
      role: null,
      companyName: null,
      secretKey: null,
      pages: [],
    },
    isLoggedIn: false,
  },
  reducers: {
    logIn: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    logOut: (state) => {
      state.user = {
        id: null,
        role: null,
        companyName: null,
        secretKey: null,
      };
      state.isLoggedIn = false;
    },
    updateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
  },
});

export const { logIn, logOut, updateUser } = authSlice.actions;
export const authState = (state) => state.auth;

export default authSlice.reducer;
