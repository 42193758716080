import { Grid, Typography } from "@mui/material";
import React from "react";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import SummaryTable from "examples/Tables/SummaryTable";
import { useEffect } from "react";
import ApiClient from "api/ApiClient";
import { useState } from "react";
import { groupBy } from "lodash";
import { FormatData, FormatAxis } from "utils/helpers";
import { COLORS } from "utils/constants";
import { FormatGranularity } from "utils/helpers";
import MDTypography from "components/MDTypography";
import { FormatClientID } from "utils/helpers";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";
import CustomToolTip from "components/CustomToolTip";
import renameKeys from "./hooks/useRenameKeys";

export default function DataQuality({
  dateRange,
  granularity,
  clientIdDescription,
  companyName,
  defaultDateRange,
}) {
  const apiClient = ApiClient;
  const [searchParams, setSearchParams] = useSearchParams();
  const [axis, setAxis] = useState(null);
  const [productsDataset, setProductsDataset] = useState(null);
  const [nonFindablesDataset, setNonFindablesDataset] = useState(null);
  const [contractTableData, setContractTableData] = useState(null);
  const [nonFindableTableData, setNonFindableTableData] = useState(null);
  const [summaryRow, setSummaryRow] = useState(null);
  const [labelDefinitions, setLabelDefinitions] = useState(null);
  const isSelectedRange =
    searchParams.get("defaultRange") === "true" ? false : true;

  // Verified, unverified and non-findable table summary
  useEffect(() => {
    if (!companyName) return;
    if (!defaultDateRange) {
      return;
    }

    let clientId = clientIdDescription === "all" ? "" : clientIdDescription;
    clientId = clientId
      ? clientId.charAt(0).toUpperCase() + clientId.slice(1).toLowerCase()
      : clientId;
    Promise.all([
      apiClient.apiA.getActivityTypeFilter(
        dateRange[0],
        dateRange[1],
        "",
        clientId,
        companyName
      ),
      apiClient.rawTotals.getTotals({
        from: dateRange[0],
        to: dateRange[1],
        filter: clientId,
        granularity: null,
        companyName,
      }),
    ]).then(([d, s]) => {
      let summary = { Verified: 0, Unverified: 0, "Non-Findable": 0 };
      let summaryRow = [];
      summary["Verified"] = Number(s[0]["% PostTag Verified"]).toFixed(2);
      summary["Unverified"] = Number(s[0]["% Unverified"]).toFixed(2);
      summary["Non-Findable"] = Number(
        s[0]["% Unverified Non-Findable"]
      ).toFixed(2);
      summaryRow = Object.keys(summary).map((key) => {
        return isNaN(summary[key]) ? "0.00%" : `${summary[key]}%`;
      });

      // Converting numbers to percentages for verified, unverified and non-findables
      d.map((d) => {
        let unverified = Number(d["% Unverified"]);
        let verified = Number(d["% PostTag Verified"]); // TODO: Change to verified
        let unverified_non_findable = Number(d["% Unverified Non-Findable"]);
        d["Unverified"] = isNaN(unverified)
          ? "0.00%"
          : `${unverified.toFixed(2)}%`;
        d["Verified"] = isNaN(verified) ? "0.00%" : `${verified.toFixed(2)}%`;
        d["Non-Findable"] = isNaN(unverified_non_findable)
          ? "0.00%"
          : `${unverified_non_findable.toFixed(2)}%`;

        renameKeys("ActivityType", "Product", d);
        delete d["Unverified Non-Findable"];
        delete d["Total"];
      });
      // Converting the data into rows for the table
      let processedRows = [];
      for (let index = 0; index < d.length; index++) {
        const row = d[index];
        const newRow = {
          Product: row["Product"],
          Verified: row["Verified"],
          Unverified: row["Unverified"],
          "Non-findable": row["Non-Findable"],
        };
        processedRows.push(newRow);
      }
      setContractTableData(processedRows);
      setSummaryRow(summaryRow);
    });

    return () => {
      setContractTableData(null);
      setSummaryRow(null);
    };
  }, [searchParams, isSelectedRange]);

  // Non-findable summary table
  useEffect(() => {
    apiClient.nonFindableCount
      .getTotals({
        from: dateRange[0],
        to: dateRange[1],
        granularity: "",
        filter: "",
        companyName,
      })
      .then((res) => {
        let nonfindableProcessedData = [];
        // Convert the data to rows for the table
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          const description = element.Code;
          const non_findable = Number(element["% Unverified Non-Findable"]);
          const percentageRounded = isNaN(non_findable)
            ? "0.00%"
            : `${non_findable.toFixed(2)}`;
          const rowData = {
            Description: description,
            "%": percentageRounded,
          };
          nonfindableProcessedData.push(rowData);
        }
        setNonFindableTableData(nonfindableProcessedData);
      });

    return () => {
      setNonFindableTableData([]);
    };
  }, [searchParams]);

  useEffect(() => {
    Promise.all([
      apiClient.products.getQualityTrends({
        from: dateRange[0],
        to: dateRange[1],
        granularity: FormatGranularity(granularity),
        companyName,
        filter: FormatClientID(searchParams.get("clientIdDescription")),
      }),
      apiClient.nonFindableCount.getTotals({
        from: dateRange[0],
        to: dateRange[1],
        granularity: FormatGranularity(granularity),
        companyName,
        filter: FormatClientID(searchParams.get("clientIdDescription")),
      }),
      apiClient.definitions.getLabelDefinitions(),
    ]).then(([products, nonFindables, definitions]) => {
      let dataset = [
        {
          label: "Unverified",
          color: "info",
          data: FormatData({
            rawData: products,
            returnDataType: (data) => {
              return data["% Unverified"];
            },
            extractDate: (data) => {
              return data.tdate;
            },
            granularity: granularity,
            from: dateRange[0],
            to: dateRange[1],
          }),
        },
        {
          label: "Non Findable",
          color: "primary",
          data: FormatData({
            rawData: products,
            returnDataType: (data) => {
              return data["% Unverified Non-Findable"];
            },
            extractDate: (data) => {
              return data.tdate;
            },
            granularity: granularity,
            from: dateRange[0],
            to: dateRange[1],
          }),
        },
      ];
      let axis = FormatAxis(dateRange[0], dateRange[1], granularity);
      setAxis(axis);
      setProductsDataset(dataset);
      let nonFindablesGrouped = Object.entries(
        groupBy(nonFindables, (nonFindable) => nonFindable.Code)
      );
      let dataset2 = nonFindablesGrouped?.map((nonFindable, idx) => {
        return {
          label: nonFindable[0],
          color: COLORS[idx % COLORS.length],
          data: FormatData({
            rawData: nonFindable[1],
            returnDataType: (data) => {
              return data["% Unverified Non-Findable"];
            },
            extractDate: (data) => {
              return data?.tdate;
            },
            granularity: granularity,
            from: dateRange[0],
            to: dateRange[1],
          }),
        };
      });
      setNonFindablesDataset(dataset2);

      let labelDefinitions = _.partition(definitions, (item) => {
        return ["Verified", "Unverified", "Unverified Non-Findable"].includes(
          item["Label"]
        );
      });

      setLabelDefinitions({
        dataQuality: labelDefinitions[0],
        nonFindable: labelDefinitions[1],
      });
    });

    return () => {
      setLabelDefinitions([]);
      setProductsDataset([]);
      setNonFindablesDataset([]);
    };
  }, [searchParams]);

  return (
    <Grid container columns={8} gap={"60px"}>
      <Grid container columns={10} spacing={3}>
        <Grid item lg={4} xs={10} sx={{ maxHeight: "26.3em" }}>
          {contractTableData && summaryRow && (
            <SummaryTable
              title={
                <MDTypography
                  variant="subtitle2"
                  color="text"
                  sx={{ fontStyle: "italic", fontSize: 14 }}
                >
                  <p className="flex items-center gap-x-2">
                    <span>
                      {isSelectedRange && "Selected Date Range"}
                      {!isSelectedRange && "Contract Range (Default)"}
                    </span>
                    <span>
                      <CustomToolTip>
                        <Typography
                          variant="p"
                          textAlign={"left"}
                          fontWeight="bold"
                          color="inherit"
                        >
                          Definitions:
                        </Typography>
                        <br />
                        {labelDefinitions &&
                          labelDefinitions?.dataQuality?.map((item, idx) => {
                            const { Label, Definitions } = item;
                            return (
                              <>
                                <Typography
                                  key={idx}
                                  variant="p"
                                  textAlign={"left"}
                                  fontWeight={"bold"}
                                  color="inherit"
                                >
                                  {Label}:
                                </Typography>
                                {Definitions}
                                <br />
                              </>
                            );
                          })}
                      </CustomToolTip>
                    </span>
                  </p>
                </MDTypography>
              }
              rows={contractTableData ?? []}
              rowHeaders={["Product", "Verified", "Unverified", "Non-findable"]}
              summaryRow={summaryRow}
            />
          )}
        </Grid>
        <Grid item lg={6} xs={10} sx={{ maxHeight: "26.3em" }}>
          {productsDataset && axis && (
            <GradientLineChart
              title="Data Quality Trend"
              chart={{
                labels: axis,
                datasets: productsDataset,
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container columns={10} spacing={3}>
        <Grid item lg={3} xs={10} sx={{ maxHeight: "26.8em" }}>
          {nonFindableTableData && (
            <SummaryTable
              title={
                <MDTypography
                  variant="h5"
                  fontWeight="light"
                  sx={{
                    padding: "10px",
                    width: "100%",
                    borderBottom: "1px solid #E0E0E0",
                    marginBottom: "10px",
                  }}
                >
                  <p className="flex items-center gap-x-2">
                    <span>Non-Findable Summary</span>
                    <CustomToolTip>
                      <Typography
                        variant="p"
                        textAlign={"left"}
                        fontWeight="bold"
                        color="inherit"
                      >
                        Definitions:
                      </Typography>
                      <br />
                      {labelDefinitions &&
                        labelDefinitions?.nonFindable?.map((item, idx) => {
                          const { Label, Definitions } = item;
                          return (
                            <>
                              <Typography
                                key={idx}
                                variant="p"
                                textAlign={"left"}
                                fontWeight={"bold"}
                                color="inherit"
                              >
                                {Label}:
                              </Typography>
                              {Definitions}
                              <br />
                            </>
                          );
                        })}
                    </CustomToolTip>
                  </p>
                </MDTypography>
              }
              rows={nonFindableTableData ?? []}
              rowHeaders={["Description", "%"]}
            />
          )}
        </Grid>
        <Grid item lg={7} xs={10}>
          {nonFindablesDataset && axis && (
            <DefaultLineChart
              title="Non-Findable Breakdown Trend"
              height="20.5em"
              chart={{
                labels: axis,
                datasets: nonFindablesDataset,
              }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
