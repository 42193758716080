export default class APIH {
  constructor(http, baseURL) {
    this.http = http;
    this.baseURL = baseURL;
  }

  async getContractComparisons(companyName) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIH.php?cmd=ContractComparison&CompanyName=${companyName}`
    );
    return result.data;
  }
}
