import { Grid } from "@mui/material";
import MDDatePicker from "components/MDDatePicker";
import { useSearchParams } from "react-router-dom";

import { useState, useEffect, useRef } from "react";
import DropdownSelect from "components/DropdownSelect/DropdownSelect";
import dayjs from "dayjs";
import ApiClient from "api/ApiClient";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import { authState } from "store/authSlice";
import { toast } from "react-toastify";
import Formatter from "utils/Formatter";
import useValidDate from "./hooks/useValidDate";
import { ROLES } from "utils/constants";

const apiClient = ApiClient;
const formatter = new Formatter();

export default function FilterBar() {
  const { defaultFromDate, defaultToDate } = useValidDate();
  const auth = useSelector(authState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [companyList, setCompanyList] = useState(auth.user.companyName);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [clientIdFilters, setClientIdFilters] = useState(null);
  const [granularity, setGranularity] = useState(
    formatter.granularityParams(searchParams.get("granularity"))
  );
  const [clientIdDescription, setClientIdDescription] = useState(
    formatter.clientParams(searchParams.get("clientIdDescription"))
  );
  const [isDefaultDateRange, setIsDefaultDateRange] = useState("true");
  const [dateFrom, setDateFrom] = useState(defaultFromDate);
  const [dateTo, setDateTo] = useState(defaultToDate);

  const datePickerInstance = useRef(null);

  {
    /* Updates the date used by data widgets every time the page is rendered */
  }
  useEffect(() => {
    if (dayjs(dateTo).isBefore(dateFrom)) {
      const fromDate = dayjs().subtract(1, "year").format("YYYY-MM-DD");
      const toDate = dayjs().format("YYYY-MM-DD");
      setDateFrom(fromDate);
      datePickerInstance.current.fromRef.flatpickr.setDate(
        fromDate,
        false,
        "Y-m-d"
      );
      setDateTo(toDate);
      datePickerInstance.current.toRef.flatpickr.setDate(
        toDate,
        false,
        "Y-m-d"
      );
      toast.error("End date cannot be before starting date");
    }
  }, [dateFrom, dateTo]);

  useEffect(() => {
    apiClient.company
      .getServicesFilters(currentCompany, auth.user.role)
      .then((res) => {
        if (res.length === 2) {
          setClientIdFilters(["all", "dev", "main"]);
        }
        if (res.length === 1) {
          const availableFilter = res[0].ClientIDDescription.toLowerCase();
          setClientIdFilters(["all", availableFilter]);
        }
        if (res.length === 0) {
          setClientIdFilters(null);
        }
      });
  }, [currentCompany]);

  useEffect(() => {
    setSearchParams({
      from: dateFrom,
      to: dateTo,
      granularity,
      clientIdDescription,
      companyName: currentCompany,
      defaultRange: isDefaultDateRange,
    });
  }, [
    dateFrom,
    dateTo,
    granularity,
    clientIdDescription,
    currentCompany,
    isDefaultDateRange,
  ]);

  const handleDateChange = (value, type) => {
    if (type === "from" && dayjs(value).isAfter(dateTo)) {
      const newDate = dayjs(dateTo).subtract(1, "day").format("YYYY-MM-DD");
      toast.error("End date cannot be before starting date");
      setDateFrom(newDate);
      datePickerInstance.current.fromRef.flatpickr.setDate(
        newDate,
        false,
        "Y-m-d"
      );
      return;
    }
    if (type === "from") {
      const newDateFrom = dayjs(value).format("YYYY-MM-DD");
      setDateFrom(newDateFrom);
      datePickerInstance.current.fromRef.flatpickr.setDate(
        newDateFrom,
        false,
        "Y-m-d"
      );
    } else {
      const newDateTo = dayjs(value).format("YYYY-MM-DD");
      setDateTo(newDateTo);
      datePickerInstance.current.toRef.flatpickr.setDate(
        newDateTo,
        false,
        "Y-m-d"
      );
    }
    setIsDefaultDateRange("false");
  };

  useEffect(() => {
    if (auth.user.role === ROLES.USER) return;
    apiClient.company.getClientList().then((res) => {
      let companyList = [];
      for (let index = 0; index < res.length; index++) {
        const companyDetails = res[index];
        companyList.push({
          label: companyDetails.CompanyName,
        });
      }
      setCompanyList(companyList);
      const cNames = companyList.map((obj) => obj.label);
      let comp = "EVRi";
      if (cNames.includes(searchParams.get("companyName"))) {
        comp = searchParams.get("companyName");
      }
      setCurrentCompany(comp);
    });
  }, []);

  return (
    <Grid
      container
      columns={12}
      spacing={"1rem"}
      sx={{ position: "sticky", top: 0, background: "#F0F2F5", zIndex: 999 }}
    >
      {companyList && currentCompany && auth.user.role === ROLES.ADMIN && (
        <Grid item md={3} xs={12}>
          <span className="text-secondary font-light italic text-sm">
            Company
          </span>
          <Autocomplete
            disablePortal
            id="autocomplete-custom"
            value={currentCompany}
            options={companyList}
            onChange={(event, newValue) => {
              setClientIdDescription("all");
              setCurrentCompany(newValue.label);
              apiClient.company
                .getClientId(newValue.label)
                .then((res) => {
                  apiClient.company
                    .getContracts(res[0]?.ClientID)
                    .then((contractData) => {
                      handleDateChange(
                        dayjs(contractData[0].CurrentContract),
                        "from"
                      );
                      datePickerInstance.current.fromRef.flatpickr.setDate(
                        dayjs(contractData[0].CurrentContract).format(
                          "YYYY-MM-DD"
                        ),
                        true,
                        "Y-m-d"
                      );
                    });
                })
                .catch((err) => console.log(err));
            }}
            renderInput={(params) => <TextField {...params} size="normal" />}
          />
        </Grid>
      )}
      <Grid
        item
        md={3}
        xs={12}
        style={{
          alignItems: "flex-end",
          display: "flex",
        }}
      >
        {/* Date picker */}
        <MDDatePicker
          sx={{ width: "100%" }}
          from={{
            input: { placeholder: "From Date" },
            options: {
              mode: "single",
              dateFormat: "Y-m-d",
              defaultDate: [dateFrom],
            },
            onChange: (date) => {
              handleDateChange(date, "from");
            },
          }}
          to={{
            input: { placeholder: "To Date" },
            options: {
              mode: "single",
              dateFormat: "Y-m-d",
              defaultDate: [dateTo],
            },
            onChange: (date) => {
              handleDateChange(date, "to");
            },
          }}
          ref={datePickerInstance}
        />
      </Grid>
      {clientIdFilters && (
        <Grid item md={2} xs={6}>
          <span className="text-secondary font-light italic text-sm">
            Services
          </span>
          <DropdownSelect
            menuItems={clientIdFilters}
            value={clientIdDescription}
            defaultValue="all"
            setValue={setClientIdDescription}
          />
        </Grid>
      )}
      <Grid item md={2} xs={6}>
        <span className="text-secondary font-light italic text-sm">
          Granularity
        </span>
        <DropdownSelect
          menuItems={["daily", "weekly", "monthly"]}
          value={granularity}
          setValue={setGranularity}
        />
      </Grid>
    </Grid>
  );
}
