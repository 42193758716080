export default class Formatter {
  granularityParams(granularity = null) {
    const options = ["daily", "weekly", "monthly"];
    if (granularity && options.includes(granularity.toLowerCase())) {
      return granularity;
    } else {
      return "monthly";
    }
  }
  clientOverviewGranularityParams(granularity = null) {
    const options = ["daily", "weekly", "monthly", "total"];
    if (granularity && options.includes(granularity.toLowerCase())) {
      return granularity;
    } else {
      return "total";
    }
  }
  clientParams(client = null) {
    const options = ["all", "main", "dev"];
    if (client && options.includes(client.toLowerCase())) {
      return client;
    } else {
      return "all";
    }
  }
}
