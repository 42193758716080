const INDEXED_MONTHS = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

const COLORS = [
  "chartColorA",
  "chartColorB",
  "chartColorC",
  "chartColorD",
  "chartColorE",
  "chartColorF",
  "chartColorG",
  "chartColorH",
  "chartColorI",
  "chartColorJ",
  "chartColorK",
  "chartColorL",
  "chartColorM",
  "chartColorN",
  "chartColorO",
  "chartColorP",
  "chartColorQ",
  "chartColorR",
  "chartColorS",
  "chartColorT",
];

const PRODUCTORDERING = [
  "PostTag Prime",
  "Lookup and Locate",
  "Address Finder",
  "Reverse Geocode",
  "Partial Address Search",
  "Locations",
  "Autocorrect",
  "Posttag Index Lookup",
  "SET Overrides",
  "GET Overrides",
  "Static Images",
  "Postcode Center (GTP108)",
  "Postcode Center (GTP125)",
];

const PAGES = {
  DASHBOARD: "Dashboard",
  SHOWCASE: "Showcase",
  OVERVIEW: "Overview",
  ESG: "ESG",
};

const ROLES = {
  ADMIN: "admin",
  USER: "user",
};

export { INDEXED_MONTHS, COLORS, PRODUCTORDERING, PAGES, ROLES };
