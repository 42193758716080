import React, { useState, u } from "react";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

export default function useValidDate() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultFromDate, setDefaultFromDate] = useState(
    dayjs(
      dayjs(searchParams.get("from")).isValid()
        ? searchParams.get("from")
        : dayjs().subtract(30, "day")
    ).format("YYYY-MM-DD")
  );
  const [defaultToDate, setDefaultToDate] = useState(
    dayjs(
      dayjs(searchParams.get("to")).isValid() ? searchParams.get("to") : dayjs()
    ).format("YYYY-MM-DD")
  );

  return {
    defaultToDate,
    defaultFromDate,
  };
}
