export default class DefinitionsApiClient {
  constructor(http, baseURL) {
    this.http = http;
    this.baseURL = baseURL;
  }

  async getLabelDefinitions() {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIF.php?cmd=getnonfindabledefinitions`
    );
    return result.data;
  }
}
