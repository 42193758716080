export default class CompanyApiClient {
  constructor(http, baseURL) {
    this.http = http;
    this.baseURL = baseURL;
  }
  async getContracts(clientID) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIF.php?cmd=getcontractdetails&clientID=${clientID}`
    );
    return result.data;
  }
  async getProductsUsed(companyName) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIF.php?cmd=getproductsused&CompanyName=${companyName}&Role=Client`
    );
    return result.data;
  }
  async getClientList() {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIF.php?cmd=getclientlist`
    );
    return result.data;
  }
  async getClientId(companyName) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIF.php?cmd=getID&companyname=${companyName}`
    );
    return result.data;
  }

  async getServicesFilters(companyName, role) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIF.php?cmd=getserviceused&CompanyName=${companyName}&Role=${role}`
    );
    return result.data;
  }
}
