import dayjs from "dayjs";

export default class TotalCountsApiClient {
  constructor(http, baseURL) {
    this.http = http;
    this.baseURL = baseURL;
  }

  async getTotals(companyName) {
    const result = await this.http.get(
      `${
        this.baseURL
      }/DashboardAPID.php?cmd=gettotals&CompanyName=${companyName}&DateStart=2022-09-01&DateEnd=${dayjs().format(
        "YYYY-MM-DD"
      )}`
    );
    return result.data.find(Boolean);
  }

  async getMonthlyTotal(from, to, companyName) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPID.php?cmd=gettotalsbymonth&CompanyName=${companyName}&DateStart=${from}&DateEnd=${to}`
    );
    return result.data;
  }

  async getWeeklyTotal(from, to, companyName) {
    const result = await this.http.get(
      `${
        this.baseURL
      }/DashboardAPID.php?cmd=gettotalsbyweek&CompanyName=${companyName}&DateStart=${dayjs()
        .subtract("1", "month")
        .format("YYYY-MM-DD")}&DateEnd=${dayjs().format("YYYY-MM-DD")}`
    );
    return result.data;
  }
}
