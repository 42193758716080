import React, { useEffect } from "react";
import { MapContainer, Marker, TileLayer, Polyline } from "react-leaflet";
import PostTagPin from "assets/misc/posttag-pin.png";
import GooglePin from "assets/misc/google-pin.png";
import L from "leaflet";
import { useMap } from "react-leaflet";

const PostTagMarker = L.icon({
  iconUrl: PostTagPin,
  iconSize: [100, 100],
  iconAnchor: [50, 50],
});

const GoogleMarker = L.icon({
  iconUrl: GooglePin,
  iconSize: [100, 100],
  iconAnchor: [50, 50],
});

const Markers = ({ posttag, google, distance }) => {
  const map = useMap();

  useEffect(() => {
    map.fitBounds([
      [posttag?.lat, posttag?.long],
      [google?.lat, google?.long],
    ]);
  }, [posttag, google]);

  return (
    <>
      <Marker
        icon={PostTagMarker}
        zIndexOffset={10}
        position={[posttag?.lat, posttag?.long]}
      />
      <Polyline
        pathOptions={{ color: "#0E385E" }}
        positions={[
          [posttag?.lat, posttag?.long],
          [google?.lat, google?.long],
        ]}
      />
      <Marker
        icon={GoogleMarker}
        autoPanOnFocus
        zIndexOffset={1}
        animate={{ duration: 0.5, zoom: { animate: true } }}
        position={[google?.lat, google?.long]}
      />
    </>
  );
};

const Map = ({ posttag, google, distance }) => {
  return (
    <MapContainer
      markerZoomAnimation={true}
      zoomAnimation
      className="h-[650px]"
      center={[54.5, -3.75]}
      zoom={5}
    >
      <TileLayer
        className="h-full"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {posttag && google && (
        <Markers distance={distance} posttag={posttag} google={google} />
      )}
    </MapContainer>
  );
};

export default Map;
