import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AuthLayout from "layouts/AuthLayout";
import bgImage from "assets/images/doors.png";
import ApiClient from "api/ApiClient";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { logIn, updateUser } from "store/authSlice";
import dayjs from "dayjs";
import PostTagLogo from "../../assets/images/posttag-logo.png";
import { PAGES, ROLES } from "utils/constants";

function errorNavigate(
  from,
  to,
  granularity,
  clientIdDescription,
  companyName
) {
  return `/dashboard?from=${from}&to=${to}&granularity=${granularity}&clientIdDescription=${clientIdDescription}&companyName=${companyName}&defaultRange=true`;
}

function Basic() {
  const apiClient = ApiClient;
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    let secretKey = null;
    const user = await apiClient.auth
      .loginV2({
        clientId: username,
        password: password,
      })
      .then((res) => {
        if (res?.info?.status !== 200) {
          toast.error("These credentials are incorrect");
          throw new Error();
        }
        localStorage.setItem("access_token", res?.["execute_token"]);
        localStorage.setItem("refresh_token", res?.["refresh_token"]);
        secretKey = res?.return;
        return {
          ...res?.info,
          Role: res?.info?.Role.toLowerCase(),
        };
      });

    const viewablePages = user["Pages"].split(",");

    dispatch(
      logIn({
        id: username,
        role: user["Role"],
        companyName:
          user["Role"] === ROLES.ADMIN ? "EVRi" : user["CompanyName"],
        secretKey: secretKey,
        pages: viewablePages,
      })
    );
    toast.success("Log in successful");

    const defaultFrom = dayjs().subtract("1", "year");
    const to = dayjs();
    const granularity = "monthly";
    const clientIdDescription = "all";
    const companyName = user["CompanyName"];

    if (!viewablePages.includes(PAGES["DASHBOARD"])) {
      navigate(`/${viewablePages.find(Boolean).toLowerCase()}`);
      return;
    }

    if (user["Role"] !== ROLES.ADMIN) {
      apiClient.company
        .getContracts(username)
        .then((contractData) => {
          const contract = contractData.find(Boolean);
          currentContractNavigate(
            contract.CurrentContract,
            defaultFrom,
            to,
            granularity,
            clientIdDescription,
            contract.CompanyName,
            viewablePages.find(Boolean)
          );
        })
        .catch(() =>
          navigate(
            errorNavigate(
              defaultFrom,
              to,
              granularity,
              clientIdDescription,
              companyName
            )
          )
        );
      return;
    }
    apiClient.company
      .getClientId("EVRi")
      .then((res) => {
        apiClient.company
          .getContracts(res[0]?.ClientID)
          .then((contractData) => {
            currentContractNavigate(
              contractData[0].CurrentContract,
              defaultFrom,
              to,
              granularity,
              clientIdDescription,
              companyName,
              viewablePages.find(Boolean)
            );
          });
      })
      .catch(() =>
        navigate(
          errorNavigate(
            defaultFrom,
            to,
            granularity,
            clientIdDescription,
            companyName
          )
        )
      );
  };

  function currentContractNavigate(
    currentContract,
    defaultFrom,
    to,
    granularity,
    clientIdDescription,
    companyName,
    rootPage
  ) {
    const startDate = dayjs(currentContract);
    const valid =
      dayjs().diff(startDate, "day") > 30 && startDate.isBefore(dayjs());
    navigate(
      rootPage === PAGES["DASHBOARD"]
        ? `/dashboard?from=${
            valid ? startDate.format("YYYY-MM-DD") : defaultFrom
          }&to=${to}&granularity=${granularity}&clientIdDescription=${clientIdDescription}&companyName=${companyName}&defaultRange=true`
        : `/${rootPage}`
    );
  }

  useEffect(() => {
    if (location.search.includes("expired")) {
      toast.warn("Your session has timed out.");
    }
  }, []);

  return (
    <AuthLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <MDBox
            sx={{
              background: "white",
              borderRadius: "10px",
              width: "fit-content",
              padding: "10px 18px",
              margin: "auto",
            }}
          >
            <img src={PostTagLogo} className="h-[60px] mx-auto" alt="" />
          </MDBox>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox
            onSubmit={(e) => handleLoginSubmit(e)}
            component="form"
            role="form"
          >
            <MDBox mb={2}>
              <MDInput
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                type="text"
                label="Username"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                label="Password"
                fullWidth
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                type="submit"
                variant="gradient"
                color="primary"
                fullWidth
              >
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                <MDTypography
                  component={Link}
                  variant="button"
                  color="info"
                  to="/"
                  fontWeight="medium"
                  textGradient
                >
                  Help?
                </MDTypography>
                <br />
                Contact memberships@posttag.com
                <br />
                <a
                  href="http://posttag.com/privacy-policy/"
                  className="underline"
                >
                  Privacy policy
                </a>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </AuthLayout>
  );
}

export default Basic;
