import React from "react";
import BaseLayout from "layouts/GenericLayout";
import { authState } from "store/authSlice";
import { useSelector } from "react-redux";

export default function NotFound() {
  const { user, isLoggedIn } = useSelector(authState);

  if (user && isLoggedIn) {
    if (!user.pages || !user.pages.find(Boolean)) {
      localStorage.clear();
      window.location.replace("/login");
      return;
    }
    window.location.replace(`/${user.pages.find(Boolean).toLowerCase()}`);
    return;
  }

  return (
    <BaseLayout wrapperClassname="min-h-screen bg-primary lg:bg-transparent">
      <div className="h-full w-full text-7xl items-center justify-center min-h-screen flex">
        <h1>Page not found</h1>
      </div>
    </BaseLayout>
  );
}
