import dayjs from "dayjs";

var localeData = require("dayjs/plugin/localeData");
var customParseFormat = require("dayjs/plugin/customParseFormat");
var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");

dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(localeData);

const NumberFormatter = (number) => {
  return Intl.NumberFormat("en-US").format(number);
};

const IsNumeric = (n) => {
  const numericRegex = /^[-+]?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?$/;
  return numericRegex.test(n);
};

const FormatMonthlyAxis = () => {
  const months = dayjs().localeData().months();
  let sortedMonths = [],
    pointer = dayjs().month();
  while (sortedMonths.length !== 12) {
    if (pointer + 1 > 11) {
      pointer = 0;
    } else {
      pointer++;
    }
    sortedMonths.push(months[pointer]);
  }
  return sortedMonths;
};

const FormatAxisByMonth = (start, end) => {
  let startingDate = dayjs(start);
  let endingDate = dayjs(end);

  let months = [];
  while (dayjs(startingDate).isSameOrBefore(endingDate)) {
    months.push(startingDate.format("MMMM-YYYY"));
    startingDate = startingDate.add(1, "month");
  }
  return months;
};

const FormatAxisByWeek = (start, end) => {
  let startingDate = dayjs(start);
  let endingDate = dayjs(end);

  let weeks = [];
  while (dayjs(startingDate).isSameOrBefore(endingDate)) {
    weeks.push(startingDate.format("DD-MM-YY"));
    startingDate = startingDate.add(1, "week");
  }
  return weeks;
};

const FormatAxisByDay = (start, end) => {
  let startingDate = dayjs(start);
  let endingDate = dayjs(end);

  let days = [];
  while (dayjs(startingDate).isSameOrBefore(endingDate)) {
    days.push(startingDate.format("DD-MM-YY"));
    startingDate = startingDate.add(1, "day");
  }
  return days;
};

const FormatAxis = (start, end, type) => {
  if (type === "daily") return FormatAxisByDay(start, end);
  if (type === "weekly") return FormatAxisByWeek(start, end);
  if (type === "monthly") return FormatAxisByMonth(start, end);
};

const FormatMonthlyData = ({
  rawData,
  returnDataType,
  extractDate,
  from,
  to,
  granularity,
}) => {
  let monthlyData = new Map();
  FormatAxis(from, to, granularity).forEach((month) => {
    monthlyData.set(dayjs(month, "MMMM-YYYY").format("YYYY-M"), 0);
  });

  rawData.forEach((val) => {
    if (monthlyData.has(dayjs(extractDate(val)).format("YYYY-M"))) {
      monthlyData.set(
        dayjs(extractDate(val)).format("YYYY-M"),
        returnDataType(val)
      );
    }
  });
  return [...monthlyData.values()];
};

const FormatWeeklyData = ({
  rawData,
  returnDataType,
  extractDate,
  from,
  to,
  granularity,
}) => {
  let weeklyData = new Map();
  FormatAxis(from, to, granularity).forEach((week) => {
    weeklyData.set(week, 0);
  });

  rawData.forEach((val) => {
    const date = extractDate(val);
    let convertedWeeklyDate = dayjs(
      getDateOfWeek(
        date.substring(date.indexOf("-") + 1),
        date.substr(0, date.indexOf("-"))
      )
    );

    let weeklyDateRange = [];
    let startWeekDate = convertedWeeklyDate;
    let endWeekDate = convertedWeeklyDate.add(1, "week");

    while (dayjs(startWeekDate).isBefore(dayjs(endWeekDate))) {
      weeklyDateRange.push(startWeekDate.format("DD-MM-YY"));
      startWeekDate = dayjs(startWeekDate).add(1, "day");
    }

    let found = false;
    weeklyDateRange.forEach((day) => {
      if (weeklyData.has(day) && !found) {
        weeklyData.set(day, returnDataType(val));
        found = true;
      }
    });
    return;
  });

  return [...weeklyData.values()];
};

const FormatDailyData = ({
  rawData,
  returnDataType,
  extractDate,
  from,
  to,
  granularity,
}) => {
  let dailyData = new Map();
  FormatAxis(from, to, granularity).forEach((day) => {
    dailyData.set(dayjs(day, "DD-MM-YY").format("YYYY-MM-DD"), 0);
  });

  rawData.forEach((val) => {
    if (dailyData.has(dayjs(extractDate(val)).format("YYYY-MM-DD"))) {
      dailyData.set(
        dayjs(extractDate(val)).format("YYYY-MM-DD"),
        returnDataType(val)
      );
    }
  });

  return [...dailyData.values()];
};

const FormatData = ({
  rawData,
  returnDataType,
  extractDate,
  granularity,
  from,
  to,
}) => {
  if (granularity === "daily")
    return FormatDailyData({
      rawData,
      returnDataType,
      extractDate,
      from,
      to,
      granularity,
    });
  if (granularity === "weekly")
    return FormatWeeklyData({
      rawData,
      returnDataType,
      extractDate,
      from,
      to,
      granularity,
    });
  if (granularity === "monthly")
    return FormatMonthlyData({
      rawData,
      returnDataType,
      extractDate,
      from,
      to,
      granularity,
    });
};

const getDateOfWeek = (w, y) => {
  let d = 1 + (w - 1) * 7;
  return new Date(y, 0, d);
};

const FormatGranularity = (granularity) => {
  if (granularity === "daily") return "Day";
  if (granularity === "weekly") return "Week";
  if (granularity === "monthly") return "Month";
};

const FormatClientID = (clientId) => {
  if (clientId === "all") return "";
  if (clientId === "dev") return "Dev";
  if (clientId === "main") return "Main";
};

export {
  NumberFormatter,
  FormatMonthlyAxis,
  FormatMonthlyData,
  getDateOfWeek,
  FormatAxis,
  FormatGranularity,
  FormatData,
  FormatClientID,
  IsNumeric,
};
