export default class MapComparisonApiClient {
  constructor(http, baseURL) {
    this.http = http;
    this.baseURL = baseURL;
  }

  async getDistance({ key, clientId, index }) {
    let mapData = new FormData();
    mapData.append("Key", "AUF,8RsoU-G!Pb!e");
    mapData.append("ClientID", "1167");
    mapData.append("Index", index);
    const result = await this.http.post(
      `${this.baseURL}/Showcase/showcase.php`,
      mapData
    );
    return result.data;
  }

  async getDistanceV2({ key, clientId, index }) {
    const result = await this.http.post(
      `${this.baseURL}/showcaseV1.php`,
      {
        Key: "AUF,8RsoU-G!Pb!e",
        ClientID: "1167",
        Index: index,
      },
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return result.data;
  }
}
