const pieChartPercentage = (contractUsage) => {
  contractUsage = parseInt(contractUsage);
  let data = [];
  let background = [];

  if (contractUsage < 100) {
    data = [contractUsage, 100 - contractUsage];
    background = ["primary", "transparent"];
  } else if (contractUsage < 200) {
    data = [contractUsage - 100, 200 - contractUsage];
    background = ["success", "primary"];
  } else {
    data = [100];
    background = ["success"];
  }

  return {
    data,
    background,
  };
};

export default pieChartPercentage;
