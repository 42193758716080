import BaseLayout from "layouts/GenericLayout";
import React, { Suspense } from "react";
import EmissionCalculator from "./components/EmissionCalculator/EmissionCalculator";
import EmissionCard from "./components/EmissionCard/EmissionCard";
import { useMutation, useQuery } from "react-query";
import ApiClient from "api/ApiClient";
import { authState } from "store/authSlice";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import { useEffect } from "react";

export default function ESGPage() {
  const [emissions, setEmissions] = React.useState(null);
  const auth = useSelector(authState);
  const { mutate } = useMutation(
    (data) =>
      ApiClient.emissions.getEmissions({
        clientId: auth.user.id,
        ...data,
      }),
    {
      onSuccess: (data) => {
        setEmissions(data);
      },
    }
  );
  const { data } = useQuery(
    ["emissions"],
    {
      queryFn: (data) =>
        ApiClient.emissions.getEmissions({
          clientId: auth.user.id,
          deliveries: 250000,
          vehicleType: "petrol_van",
          averageDistance: 145,
        }),
    },
    {
      suspense: true,
    }
  );

  useEffect(() => {
    if (data) {
      setEmissions(data);
    }
  }, [data]);

  return (
    <BaseLayout wrapperClassname="min-h-screen bg-primary lg:bg-transparent">
      <div className="flex flex-col pt-2 lg:pt-2 px-2 lg:px-5 gap-y-5 lg:gap-y-10 w-full items-center">
        <div className="w-full lg:w-1/2">
          <EmissionCalculator
            onCalculate={(e) => {
              mutate({
                deliveries: e.deliveries,
                vehicleType: e.vehicleType,
                averageDistance: e.averageDistance,
              });
            }}
          />
        </div>
        <div className="flex lg:mt-auto flex-row lg:flex-row gap-2 lg:gap-10 w-full">
          <Suspense fallback={<Skeleton width={100} height={100} />}>
            <EmissionCard
              title="Wasted miles driven"
              stats={[
                {
                  number: emissions?.["TotalMilesWastedPerDay"],
                  unit: "Miles",
                  time: "per day",
                },
                {
                  number: emissions?.["TotalMilesWastedPerYear"],
                  unit: "Miles",
                  time: "per year",
                },
              ]}
            />
            <EmissionCard
              title={
                <p>
                  CO<sub>2</sub> emissions saved
                </p>
              }
              stats={[
                {
                  number: emissions?.["TotalGHGPerDay"],
                  unit: "Tonnes",
                  time: "per day",
                },
                {
                  number: emissions?.["TotalGHGPerYear"],
                  unit: "Tonnes",
                  time: "per year",
                },
              ]}
            />
          </Suspense>
        </div>
        <p className="lg:mt-auto text-center pb-5 text-white lg:text-secondary text-xs lg:text-sm">
          CO<sub>2</sub> emission savings are calculated based on a standard
          diesel van and an average PostTag distance reduction in the last mile
          of 145m.
        </p>
      </div>
    </BaseLayout>
  );
}
