import React, { useState, useEffect } from "react";
import Sidebar from "components/Sidebar";
import MobileSidebar from "components/Sidebar/MobileSidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { authState } from "store/authSlice";
import { useSelector } from "react-redux";

export default function GenericLayout({
  children,
  sidebarRefs,
  wrapperClassname = "",
}) {
  const { user } = useSelector(authState);
  const [sidebar, setSidebar] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const preventSameRouteNavigation = (route) => {
    if (location.pathname.includes(`${route}`)) return;
    navigate(`/${route}`);
  };

  useEffect(() => {
    let sidebarItems = [
      {
        title: "Dashboard",
        icon: "ri:layout-masonry-fill",
        onSelect: () => preventSameRouteNavigation("dashboard"),
        subItems: [
          {
            name: "Overview",
            icon: "entypo:bar-graph",
            onClick: () => {},
          },
          {
            name: "Data Quality",
            icon: "fa6-solid:magnifying-glass",
            onClick: () => {},
          },
          {
            name: "Products",
            icon: "bi:box",
            onClick: () => {},
          },
          {
            name: "Services",
            icon: "charm:stack",
            onClick: () => {},
          },
          {
            name: "Data Tables",
            icon: "clarity:clipboard-line",
            onClick: () => {},
          },
        ],
      },
      {
        title: "Showcase",
        icon: "material-symbols:globe-asia",
        subItems: [],
        onSelect: () => preventSameRouteNavigation("showcase"),
      },
      {
        title: "Overview",
        icon: "clarity:clipboard-line",
        subItems: [],
        onSelect: () => preventSameRouteNavigation("overview"),
      },
      {
        title: "ESG",
        icon: "gis:search-globe",
        subItems: [],
        onSelect: () => preventSameRouteNavigation("esg"),
      },
    ];

    if (sidebarRefs) {
      sidebarItems = sidebarItems.map((item) => {
        const sideBarRefsForItem = sidebarRefs.for === item.title;
        if (sideBarRefsForItem) {
          const updateSubItem = item?.subItems.map((subItem) => {
            const ref = sidebarRefs.refs.find((ref) => {
              return ref.name === subItem.name;
            });
            return {
              ...subItem,
              ref: ref ? ref.ref : null,
            };
          });
          return {
            ...item,
            subItems: updateSubItem,
          };
        }
        return item;
      });
    }
    setSidebar(sidebarItems.filter((item) => user.pages.includes(item.title)));
  }, []);

  return (
    <>
      <div className="lg:hidden">
        <MobileSidebar />
      </div>
      <div className={`flex px-5 gap-x-5 xl:px-2 ${wrapperClassname}`}>
        <div className="lg:col-span-3 hidden lg:block">
          {!!sidebar && sidebar.length > 0 && (
            <Sidebar sidebarItems={sidebar} />
          )}
        </div>
        {children}
      </div>
    </>
  );
}
