import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { MaterialUIControllerProvider } from "context";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import theme from "assets/theme";
import { ToastContainer } from "react-toastify";
import { store, persistedStore } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistedStore}>
            <ThemeProvider theme={theme}>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                limit={2}
                rtl={false}
              />
              <App />
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
