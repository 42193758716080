import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import PostTagDropdownSelect from "components/DropdownSelect/PostTagDropdownSelect";

function PostTagStatisticsCard({
  title,
  count,
  percentage,
  dropdown,
  children,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card className="w-full">
      <div className="p-4 h-full flex flex-col justify-between">
        <div className="flex flex-col items-end">
          {dropdown && (
            <PostTagDropdownSelect
              fullWidth={false}
              style={{
                "& fieldset": {
                  border: "none",
                  borderWidth: 0,
                },
              }}
              className="without-border"
              menuItems={dropdown?.items}
              value={dropdown?.selected}
              setValue={dropdown?.setSelected}
            />
          )}
          <MDBox mb={0.5} textAlign="right" lineHeight={1}>
            <MDTypography
              variant="button"
              color="#495057"
              textTransform="capitalize"
            >
              {title}
            </MDTypography>
          </MDBox>
          <MDTypography variant="h5" textAlign="right" fontWeight="bold">
            {count}
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular">
            {children}
          </MDTypography>
        </div>

        <div className="flex flex-col mt-auto w-full h-16">
          <hr className="my-2" />
          <span className="h-full">
            {percentage?.value && (
              <MDTypography
                variant="button"
                fontWeight="bold"
                color={percentage.color}
              >
                {percentage.value}&nbsp;
              </MDTypography>
            )}

            {percentage?.label && (
              <MDTypography
                variant="button"
                textAlign="left"
                fontWeight="regular"
                color={darkMode ? "text" : "secondary"}
              >
                {percentage.label}
              </MDTypography>
            )}
          </span>
        </div>
      </div>
    </Card>
  );
}

// Setting default values for the props of DefaultStatisticsCard
PostTagStatisticsCard.defaultProps = {
  percentage: {
    color: "success",
    value: "",
    label: "",
  },
  dropdown: false,
};

// Typechecking props for the DefaultStatisticsCard
PostTagStatisticsCard.propTypes = {
  title: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      setSelected: PropTypes.func,
      items: PropTypes.node,
      selected: PropTypes.string,
    }),
  ]),
  children: PropTypes.node,
};

export default PostTagStatisticsCard;
