export default class RawTotalsApiClient {
  constructor(http, baseURL) {
    this.http = http;
    this.baseURL = baseURL;
  }
  async getTotals({ from, to, filter = "", granularity, companyName }) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIJ.php?cmd=gettotalsfilter&CompanyName=${companyName}&DateStart=${from}&DateEnd=${to}&Filter=${filter}&Granularity=${granularity}`
    );
    return result.data;
  }

  async getTotalsByInterval({ interval, companyName }) {
    const result = await this.http.get(
      `${this.baseURL}/DashboardAPIH.php?cmd=getUsage3&CompanyName=${companyName}&Interval=${interval}`
    );
    return result.data;
  }
}
