import React from "react";
import { Grid } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import { useEffect } from "react";
import ApiClient from "api/ApiClient";
import { useState } from "react";
import { getDateOfWeek } from "utils/helpers";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

export default function DataTables({
  dateRange,
  clientIdDescription,
  granularity,
  companyName,
  defaultDateRange,
}) {
  const apiClient = ApiClient;
  const [tableColumns, setTableColumns] = useState(null);
  const [tableRows, setTableRows] = useState(null);
  const [searchParams] = useSearchParams();
  const isSelectedRange =
    searchParams.get("defaultRange") === "true" ? false : true;

  function formatDate(date, granularity) {
    if (granularity === "Week") {
      date = dayjs(
        getDateOfWeek(
          date.substring(date.indexOf("-") + 1),
          date.substr(0, date.indexOf("-"))
        )
      ).format("YYYY-MM-DD");
    }
    if (granularity === "Month") {
      date = dayjs(date).format("YYYY-MM");
    }
    if (granularity === "Day") {
      date = dayjs(date).format("YYYY-MM-DD");
    }

    return date;
  }

  useEffect(() => {
    if (!defaultDateRange) {
      return;
    }
    if (!companyName) {
      return;
    }

    const clientIdMappings = {
      all: "",
      dev: "Dev",
      main: "Main",
    };

    const granularityMappings = {
      total: "Total",
      monthly: "Month",
      weekly: "Week",
      daily: "Day",
    };

    let clientId = clientIdMappings[clientIdDescription];
    let gran = granularityMappings[granularity];

    apiClient.customersOverview
      .getTotalsSales(companyName, dateRange[0], dateRange[1], clientId, gran)
      .then((res) => {
        // Create the table columns
        let tableColumns = [
          {
            Header: "Client ID",
            accessor: "clientId",
          },
          {
            Header: "Client Name",
            accessor: "clientName",
          },
          {
            Header: "Total Lookups",
            accessor: "totalLookups",
          },
          {
            Header: "PostTag Verified",
            accessor: "postTagVerifiedNum",
          },
          {
            Header: "PostTag Verified %",
            accessor: "postTagVerifiedPercent",
          },
          {
            Header: "PostTag Unverified",
            accessor: "postTagUnverifiedNum",
          },
          {
            Header: "PostTag Unverified %",
            accessor: "postTagUnverifiedPercent",
          },
          {
            Header: "PostTag Non-Findable",
            accessor: "postTagNonFindableNum",
          },
          {
            Header: "PostTag Non-Findable %",
            accessor: "postTagNonFindablePercent",
          },
        ];

        // If there granularity is not total, we can have a column to show the date associated with the data
        if (gran !== "Total") {
          tableColumns.unshift({
            Header: gran === "Week" ? "Start of Week" : gran,
            accessor: "date",
          });
        }

        let tableRows = [];

        res.forEach((element) => {
          tableRows.push({
            date: formatDate(element["tdate"], gran),
            clientId: element["ClientID"],
            clientName: element["ApiName"],
            totalLookups: element["Total"],
            postTagVerifiedNum: element["PostTag Verified"],
            postTagVerifiedPercent: parseFloat(
              element["% PostTag Verified"]
            ).toFixed(2),
            postTagUnverifiedNum: element["Unverified"],
            postTagUnverifiedPercent: parseFloat(
              element["% Unverified"]
            ).toFixed(2),
            postTagNonFindableNum: element["Unverified Non-Findable"],
            postTagNonFindablePercent: parseFloat(
              element["% Unverified Non-Findable"]
            ).toFixed(2),
          });
        });

        setTableColumns(tableColumns);
        setTableRows(tableRows);
      });
  }, [searchParams, isSelectedRange, defaultDateRange]);

  return (
    <>
      <Grid>
        {tableRows && tableColumns && (
          <DataTable
            entriesPerPage={{ defaultValue: 25, entries: [5, 10, 15, 20, 25] }}
            table={{
              columns: tableColumns,
              rows: tableRows,
            }}
          />
        )}
      </Grid>
    </>
  );
}
