import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import ApiClient from "api/ApiClient";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { useSearchParams } from "react-router-dom";
import {
  FormatAxis,
  FormatClientID,
  FormatData,
  NumberFormatter,
  FormatGranularity,
} from "utils/helpers";
import { PRODUCTORDERING } from "utils/constants";
import MDTypography from "components/MDTypography";
var groupBy = require("lodash/groupBy");

export default function UsageByProduct({
  companyName,
  dateRange,
  granularity,
  clientIdDescription,
}) {
  const apiClient = ApiClient;
  const [products, setProducts] = useState([]);
  const [axis, setAxis] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!companyName) {
      return;
    }
    Promise.all([
      apiClient.products.getTotals({
        from: dateRange[0],
        to: dateRange[1],
        filter: FormatClientID(clientIdDescription),
        granularity: FormatGranularity(granularity),
        companyName: companyName,
      }),
    ]).then(([products]) => {
      const groupedProducts = Object.entries(
        groupBy(products, (product) => product.ActivityType)
      );
      groupedProducts.sort(
        (a, b) => PRODUCTORDERING.indexOf(a[0]) - PRODUCTORDERING.indexOf(b[0])
      );
      let axis = FormatAxis(dateRange[0], dateRange[1], granularity);
      setAxis(axis);
      setProducts(groupedProducts);
    });

    return () => {
      setAxis(null);
      setProducts(null);
    };
  }, [searchParams, dateRange[0], dateRange[1]]);

  return (
    <>
      <Grid container overflow={"hidden"} sx={{ minHeight: "400px" }}>
        <Grid item xs={12} overflow="hidden">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            breakpoints={{
              1280: {
                slidesPerView: 2,
              },
            }}
            modules={[Pagination]}
            pagination={{ clickable: true }}
          >
            {axis &&
              products &&
              products.map((product, idx) => {
                if (product[0] === "No Activity") return null;
                const productName = product[0];
                const values = product[1];
                const data = FormatData({
                  rawData: values,
                  returnDataType: (data) => {
                    return data?.Total;
                  },
                  extractDate: (data) => {
                    return data?.tdate;
                  },
                  granularity: granularity,
                  from: dateRange[0],
                  to: dateRange[1],
                });
                const totalProductUsage = product[1].reduce((acc, curr) => {
                  return acc + Number(curr?.Total);
                }, 0);
                return (
                  <SwiperSlide key={idx}>
                    <VerticalBarChart
                      class="w-full"
                      icon={{ color: "info", component: "leaderboard" }}
                      title={
                        <div className="flex w-full items-center justify-between">
                          <div className="w-full">{productName}</div>
                          <div className="flex text-right flex-col gap-y-2">
                            <MDTypography
                              variant="button"
                              color="text"
                              textTransform="capitalize"
                            >
                              Total
                            </MDTypography>
                            <span className="font-bold">
                              {NumberFormatter(totalProductUsage)}
                            </span>
                          </div>
                        </div>
                      }
                      chart={{
                        labels: axis,
                        datasets: [
                          {
                            label: "Total",
                            color: "dark",
                            data: data,
                          },
                        ],
                      }}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </Grid>
      </Grid>
    </>
  );
}
