import { Card, Slider } from "@mui/material";
import { NumericFormat } from "react-number-format";
import React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function EmissionCalculator({ onCalculate }) {
  const [totalDeliveries, setTotalDeliveries] = React.useState(500000);
  const { watch, setValue, getValues } = useForm({
    defaultValues: {
      totalDeliveries: 250000,
      averageDistance: 145,
      vechileType: "petrol_van",
    },
  });

  useEffect(() => {
    if (watch("totalDeliveries") > 500000) {
      setValue("totalDeliveries", 500000);
    }
    if (watch("totalDeliveries") < 0) {
      setValue("totalDeliveries", 0);
    }
  }, [watch("totalDeliveries")]);

  return (
    <div className="flex flex-col gap-y-3">
      <h1 className="text-white bg-success w-fit m-auto px-8 py-3 lg:-0 lg:bg-transparent rounded-lg lg:text-secondary font-semibold lg:text-xl text-center">
        Wasted miles & CO<sub>2</sub> calculator
      </h1>
      <Card className="flex gap-y-5 p-4 lg:p-8 flex-col items-center">
        <div className="flex flex-col items-center">
          <p className="font-semibold text-secondary pb-2">
            Total Deliveries Daily
          </p>
          <NumericFormat
            allowLeadingZeros={false}
            allowNegative={false}
            onChange={(e) =>
              setValue(
                "totalDeliveries",
                parseInt(e.target.value.replace(",", ""))
              )
            }
            value={watch("totalDeliveries")}
            thousandSeparator=","
            className="bg-transparent border-2 py-2 focus:outline-none focus:border-success border-success rounded-lg text-center font-bold"
          ></NumericFormat>
        </div>

        <Slider
          value={watch("totalDeliveries") || totalDeliveries}
          onChange={(e) => setValue("totalDeliveries", e.target.value)}
          step={10000}
          min={0}
          max={500000}
          color="success"
          valueLabelDisplay="auto"
        />
        <button
          onClick={() =>
            onCalculate({
              vehicleType: getValues("vechileType"),
              averageDistance: getValues("averageDistance"),
              deliveries: getValues("totalDeliveries"),
            })
          }
          className="px-8 py-2 w-full md:w-1/2 bg-success text-white rounded-lg"
        >
          Calculate
        </button>
      </Card>
    </div>
  );
}
