import SummaryCards from "./components/SummaryCards";
import React from "react";
import DataQuality from "./components/DataQuality";
import Overview from "./components/Overview";
import UsageByProduct from "./components/UseageByProduct";
import UsageByService from "./components/UsageByService/UsageByService";
import DataTables from "./components/DataTables/DataTables";
import FilterBar from "components/FilterBar";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import MDTypography from "components/MDTypography";
import { useSelector } from "react-redux";
import { authState } from "store/authSlice";
import ApiClient from "api/ApiClient";
import dayjs from "dayjs";
import { useEffect } from "react";
import BaseLayout from "layouts/GenericLayout";
import { ROLES } from "utils/constants";

export default function Dashboard() {
  const apiClient = ApiClient;
  const auth = useSelector(authState);
  const user = auth.user;
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const dateRange = [searchParams.get("from"), searchParams.get("to")];
  const granularity = searchParams.get("granularity");
  const clientIdDescription = searchParams.get("clientIdDescription");
  const companyName =
    user.role !== ROLES.ADMIN
      ? user["companyName"]
      : searchParams.get("companyName");

  const [defaultDateRange, setDefaultDateRange] = useState(null);

  window.onpopstate = () => {
    navigate(0);
  };

  useEffect(() => {
    if (!companyName) {
      return;
    }
    if (auth.user.role !== ROLES.ADMIN) {
      apiClient.company.getContracts(auth.user.id).then((contractData) => {
        let defaultRange = [
          dayjs(contractData[0].CurrentContract).format("YYYY-MM-DD"),
          dayjs().format("YYYY-MM-DD"),
        ];
        setDefaultDateRange(defaultRange);
      });
    }
    apiClient.company.getClientId(companyName).then((res) => {
      apiClient.company.getContracts(res[0]?.ClientID).then((contractData) => {
        let defaultRange = [
          dayjs(contractData[0].CurrentContract).format("YYYY-MM-DD"),
          dayjs().format("YYYY-MM-DD"),
        ];
        setDefaultDateRange(defaultRange);
      });
    });
  }, [companyName]);

  const overviewRef = useRef();
  const dqRef = useRef();
  const productsRef = useRef();
  const servicesRef = useRef();
  const dataTablesRef = useRef();
  const sidebarRefs = {
    for: "Dashboard",
    refs: [
      {
        name: "Overview",
        ref: overviewRef,
      },
      {
        name: "Data Quality",
        ref: dqRef,
      },
      {
        name: "Products",
        ref: productsRef,
      },
      {
        name: "Services",
        ref: servicesRef,
      },
      {
        name: "Data Tables",
        ref: dataTablesRef,
      },
    ],
  };

  return (
    <BaseLayout sidebarRefs={sidebarRefs}>
      <div className="w-full overflow-hidden flex flex-col gap-y-10">
        <FilterBar />
        {dateRange &&
          granularity &&
          clientIdDescription &&
          companyName &&
          defaultDateRange && (
            <>
              <SummaryCards
                clientIdDescription={clientIdDescription}
                companyName={companyName}
                dateRange={dateRange}
                defaultDateRange={defaultDateRange}
              />
              <div className="">
                <MDTypography
                  ref={overviewRef}
                  color="secondary"
                  className="dashboard-section"
                >
                  Overview
                </MDTypography>
                <Overview
                  companyName={companyName}
                  clientIdDescription={clientIdDescription}
                  granularity={granularity}
                  dateRange={dateRange}
                />
              </div>
              <div className="">
                <MDTypography
                  ref={dqRef}
                  color="secondary"
                  className="dashboard-section"
                >
                  Data Quality
                </MDTypography>
                <DataQuality
                  defaultDateRange={defaultDateRange}
                  companyName={companyName}
                  clientIdDescription={clientIdDescription}
                  granularity={granularity}
                  dateRange={dateRange}
                />
              </div>
              <div className="">
                <MDTypography
                  ref={productsRef}
                  color="secondary"
                  className="dashboard-section"
                >
                  Products
                </MDTypography>
                <UsageByProduct
                  companyName={companyName}
                  granularity={granularity}
                  dateRange={dateRange}
                  clientIdDescription={clientIdDescription}
                />
              </div>
              <div className="">
                <MDTypography
                  ref={servicesRef}
                  color="secondary"
                  className="dashboard-section"
                >
                  Services
                </MDTypography>
                <UsageByService
                  companyName={companyName}
                  granularity={granularity}
                  dateRange={dateRange}
                />
              </div>
              <div className="">
                <MDTypography
                  ref={dataTablesRef}
                  color="secondary"
                  className="dashboard-section"
                >
                  Data Tables
                </MDTypography>
                <DataTables
                  defaultDateRange={defaultDateRange}
                  companyName={companyName}
                  clientIdDescription={clientIdDescription}
                  granularity={granularity}
                  dateRange={dateRange}
                />
              </div>
            </>
          )}
      </div>
    </BaseLayout>
  );
}
