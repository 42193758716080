import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";

function DataTableHeadCell({ width, children, sorted, align, ...rest }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDBox
      component="th"
      width={width}
      py={1.5}
      px={3}
      sx={({ palette: { light }, borders: { borderWidth } }) => ({
        borderBottom: `${borderWidth[1]} solid ${light.main}`,
      })}
    >
      <MDBox
        {...rest}
        position="relative flex flex-col"
        textAlign={align}
        color={darkMode ? "white" : "secondary"}
        opacity={0.7}
        sx={({ typography: { size, fontWeightBold } }) => ({
          fontSize: size.xxs,
          fontWeight: fontWeightBold,
          textTransform: "uppercase",
          cursor: sorted && "pointer",
          userSelect: sorted && "none",
        })}
      >
        {sorted && (
          <div className="flex items-center text-left justify-center">
            <MDBox
              top={0}
              sx={({ typography: { size } }) => ({
                fontSize: size.lg,
              })}
            >
              <MDBox
                color={sorted === "asce" ? "text" : "secondary"}
                opacity={sorted === "asce" ? 1 : 0.5}
              >
                <Icon className="translate-y-1/2">arrow_drop_up</Icon>
              </MDBox>
              <MDBox
                color={sorted === "desc" ? "text" : "secondary"}
                opacity={sorted === "desc" ? 1 : 0.5}
              >
                <Icon className="-translate-y-1/3">arrow_drop_down</Icon>
              </MDBox>
            </MDBox>
            {children}
          </div>
        )}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DataTableHeadCell
DataTableHeadCell.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "left",
};

// Typechecking props for the DataTableHeadCell
DataTableHeadCell.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  sorted: PropTypes.oneOf([false, "none", "asce", "desc"]),
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableHeadCell;
