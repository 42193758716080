import { combineReducers, configureStore } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import storage from 'redux-persist/lib/storage'
import authReducer from "store/authSlice";

const persistConfig = {
    key: 'root',
    storage
}
const reducer = combineReducers({
    auth: authReducer
})
const persistedReducer = persistReducer(persistConfig, reducer)


export const store = configureStore({
    reducer: persistedReducer
})

export const persistedStore = persistStore(store)

