import { useState } from "react";
import { Switch } from "@mui/material";

export default function PostTagDistanceRow({ distance, postTagDistance }) {
  const [showPostTagDistance, setShowPostTagDistance] = useState(false);
  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex lg:flex-col xl:flex-row">
        <div className="bg-primary flex p-5 w-full gap-x-2 justify-between items-center rounded-md text-white">
          <p>Straight Line Distance</p>
          <Switch
            onChange={() => setShowPostTagDistance(!showPostTagDistance)}
          />
        </div>
        <div className="bg-white flex items-center text-black w-full p-5 rounded-md font-semibold">
          <p>{distance}</p>
        </div>
      </div>

      {showPostTagDistance && (
        <div className="flex lg:flex-col xl:flex-row">
          <div className="bg-primary flex p-5 w-full gap-x-2 justify-between items-center rounded-md text-white">
            <p>PostTag Distance</p>
          </div>
          <div className="bg-white flex items-center text-black w-full p-5 rounded-md font-semibold">
            <p>{postTagDistance}</p>
          </div>
        </div>
      )}
    </div>
  );
}
