import React, { useEffect, useState } from "react";
import ApiClient from "api/ApiClient";
import { useSelector } from "react-redux";
import { authState } from "store/authSlice";
import DistanceRow from "./DistanceRow";
import AddressRow from "./AddressRow";
import PostTagDistanceRow from "./PostTagDistance";
import { useMutation } from "react-query";

export default function AddressDetail({ address, onMapDetails }) {
  const apiClient = ApiClient;
  const [addressData, setAddressData] = useState(null);
  const [addressDistance, setAddressDistance] = useState(null);
  const auth = useSelector(authState);
  const user = auth.user;
  const parseValue = (val) => {
    return parseFloat(val).toFixed(2);
  };
  const { mutate } = useMutation(
    (data) => apiClient.showcase.getAddressInformation(data),
    {
      onSuccess: (data) => {
        if (!data?.status.includes("200")) return;
        const address = data?.posttag_data?.data.find(Boolean);
        setAddressData({
          rowOne: [
            address["Organisation"],
            address["Department"],
            address["Sub Building"],
          ],
          rowTwo: [
            address["Number"],
            address["Building"],
            address["Sub Street"],
            address["Street"],
          ],
          rowThree: address["County"],
          rowFour: address["Town"],
          rowFive: address["Area"],
          rowSix: address["Post Code"],
        });
        setAddressDistance({
          posttag: data["posttag_result"],
          google: data["google_result"],
          distance: data["sl_distance"],
          posttagDistance: data["posttag_distance"],
        });
        onMapDetails({
          posttag: {
            lat: data["posttag_result"]?.["latitude"],
            long: data["posttag_result"]?.["longitude"],
          },
          google: {
            lat: data["google_result"]?.["latitude"],
            long: data["google_result"]?.["longitude"],
          },
          distance: data["posttag_distance"]["total_distance"],
        });
      },
    }
  );

  useEffect(() => {
    if (address) {
      mutate({
        clientId: user.id,
        input: address?.Idx,
      });
    }
  }, [address]);

  return (
    <div className="w-full flex gap-y-5 flex-col">
      {addressData && (
        <div className="flex flex-col gap-y-3">
          {addressData?.rowOne.find(Boolean) && (
            <AddressRow text={addressData?.rowOne.filter(Boolean).join(", ")} />
          )}
          {addressData?.rowTwo.find(Boolean) && (
            <AddressRow text={addressData?.rowTwo.filter(Boolean).join(", ")} />
          )}
          {addressData?.rowThree && <AddressRow text={addressData?.rowThree} />}
          {addressData?.rowFour && <AddressRow text={addressData?.rowFour} />}
          {addressData?.rowFive && <AddressRow text={addressData?.rowFive} />}
          {addressData?.rowSix && <AddressRow text={addressData?.rowSix} />}
        </div>
      )}

      <div className="flex flex-col gap-y-3">
        <DistanceRow
          text={
            <p className="flex flex-col">
              <span>Lat: {addressDistance?.posttag?.latitude}</span>
              <span>Long: {addressDistance?.posttag?.longitude}</span>
            </p>
          }
          label={"PostTag's Location"}
        />
        <DistanceRow
          text={
            <p className="flex flex-col">
              <span>Lat: {addressDistance?.google?.latitude}</span>
              <span>Long: {addressDistance?.google?.longitude}</span>
            </p>
          }
          label={"Google's Location"}
        />
        <PostTagDistanceRow
          postTagDistance={
            addressDistance?.posttagDistance
              ? `${parseValue(
                  addressDistance?.posttagDistance?.total_distance
                )} Meters`
              : "N/A"
          }
          distance={`${parseValue(addressDistance?.distance)} Meters`}
        />
      </div>
    </div>
  );
}
