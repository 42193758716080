import AddressDetail from "./components/AddressDetail";
import Map from "./components/Map";
import Searchbar from "./components/Searchbar";
import BaseLayout from "layouts/GenericLayout";
import React, { useState } from "react";

const Showcase = () => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [searchbarIsOpen, setSearchbarIsOpen] = useState(false);
  const [mapMarkers, setMapMarkers] = useState(null);

  return (
    <BaseLayout>
      <div className="lg:col-span-9 pt-5 grid-flow-row gap-y-5 col-span-full w-full min-h-screen grid grid-cols-12">
        <div className="col-span-full">
          <Searchbar
            isOpen={(e) => setSearchbarIsOpen(e)}
            onSelect={(e) => {
              setSelectedAddress(e);
            }}
          />
        </div>

        {selectedAddress && !searchbarIsOpen && (
          <div className="col-span-full lg:col-span-5">
            <AddressDetail
              onMapDetails={(e) => setMapMarkers(e)}
              address={selectedAddress}
            />
          </div>
        )}

        {!searchbarIsOpen && (
          <div className="lg:col-start-7 col-span-full">
            <Map
              google={mapMarkers?.google}
              posttag={mapMarkers?.posttag}
              distance={mapMarkers?.distance}
            />
          </div>
        )}
      </div>
    </BaseLayout>
  );
};

export default Showcase;
