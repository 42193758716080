export default class AuthApiClient {
  constructor(http, baseURL) {
    this.http = http;
    this.baseURL = baseURL;
  }
  async login(formData) {
    const result = await this.http.post(
      `${this.baseURL}/PS01.php?cmd=loginmain`,
      formData
    );
    return result.data;
  }

  async loginV2({ clientId, password }) {
    const result = await this.http.post(
      `${this.baseURL}/dashboard_accountsV1.php`,
      {
        clientid: clientId,
        password: password,
        cmd: "login",
      },
      {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }
    );
    return result.data;
  }
}
