import React from 'react'
import { Grid } from '@mui/material'
import VerticalBarChart from 'examples/Charts/BarCharts/VerticalBarChart'
import StackedDoughnutChart from 'examples/Charts/DoughnutCharts/StackedDoughnutCharts'
import ApiClient from 'api/ApiClient'
import { useEffect } from 'react'
import { useState } from 'react'
import { FormatAxis } from 'utils/helpers'
import { useSearchParams } from 'react-router-dom'
import { FormatGranularity } from 'utils/helpers'
import { FormatData } from 'utils/helpers'
import MDTypography from 'components/MDTypography'
import { FormatClientID } from 'utils/helpers'
import dayjs from 'dayjs'
import pieChartPercentage from './hooks/usePieChartPercentage'

export default function OverviewLayout({dateRange, clientIdDescription, granularity, companyName}) {
  const apiClient = ApiClient
  const [searchParams, setSearchParams] = useSearchParams()
  const [usage, setUsage] = useState(null)
  const [axis, setAxis] = useState(null)
  const [contractUsage, setContractUsage] = useState(null)
  const [dataUsage, setDataUsage] = useState(null)

  useEffect(() => {
    if(!companyName) return
    setContractUsage({"Contract Usage Limit": 0});
    setDataUsage({"Usage Limit To Date": 0});
    Promise.all([
      apiClient.proportions.getContractUsage(companyName),
      apiClient.proportions.getDataUsage(companyName)
    ]).then(([contractUsage, dataUsage]) => {
      setContractUsage((contractUsage['Contract Usage Limit'] * 1).toFixed(2))
      setDataUsage(dataUsage['Usage Limit To Date'])
    })
  }, [searchParams])

  useEffect(() => {
    if(!companyName) return
    setUsage([]);
    Promise.all([
      apiClient.rawTotals.getTotals({
        from: dateRange[0],
        to: dateRange[1],
        filter: FormatClientID(clientIdDescription),
        granularity: FormatGranularity(granularity),
        companyName: companyName,
      }),
    ]).then(([rawTotals]) => {
      let data = FormatData({
        rawData: rawTotals,
        returnDataType: (data) => { return data?.Total },
        extractDate: (data) => { return data?.tdate },
        granularity: searchParams.get('granularity'),
        from: searchParams.get('from'),
        to: searchParams.get('to') 
      })

      let a = FormatAxis(
        searchParams.get('from'),
        searchParams.get('to'),
        searchParams.get('granularity')
      )
      setUsage(data);
      setAxis(a);
    })

    return () => {
      setUsage(null);
    };
  }, [searchParams])

  return (
    <>
    <Grid container columns={6} spacing={3} alignItems="stretch">
        <Grid item lg={4} xs={6} height='100%' sx={{minHeight: '100%'}} className='h-100'>
          {
            usage && axis && 
              <VerticalBarChart
              class='w-full h-full'
              icon={{ color: "info", component: "leaderboard" }}
              title="Total Usage"
              height="24.25em"
              chart={{
              labels: axis,
              datasets: [{
                  color: "dark",
                  data: usage,
              }],
              }}
          />  
          }
        </Grid>
        <Grid item lg={2} xs={6} sx={{minHeight: '100%', position: 'relative'}} display='flex' flexDirection={'column'} gap='0rem'>
          <MDTypography 
          variant="subtitle2"
          color="text"
          sx={{position: 'absolute', top: 0, fontStyle: 'italic', fontSize: 14}}>Contract Range (Default)</MDTypography>
          {
            contractUsage && 
              <StackedDoughnutChart
              style={{'borderRadius': '10px 10px 0px 0px'}}
              description="Volume Utilisation: Contract Full Allowance"
              height="10em"
              percentage={`${contractUsage}%`}
              chart={{
                labels: ["Contract Usage"],
                datasets: {
                  label: "Contract Usage",
                  data: pieChartPercentage(contractUsage)['data'],
                  backgroundColors: pieChartPercentage(contractUsage)['background'] 
                },
              }}
            />
          }
          {
            dataUsage && 
              <StackedDoughnutChart
              style={{'borderRadius': '0px 0px 10px 10px'}}
              description={`Volume Utilisation: Contract Year To Date – ${dayjs().format('DD/MM/YYYY')}`}
              height="10em"
              percentage={`${(dataUsage * 1).toFixed(2)}%`}
              chart={{
                labels: ["Contract Usage"],
                datasets: {
                  label: "Contract Usage",
                  data: pieChartPercentage(dataUsage)['data'],
                  backgroundColors: pieChartPercentage(dataUsage)['background']
                },
              }}
            />
          }
        </Grid>
    </Grid>
    </>
  )
}
