export default class CustomerOverviewApiClient {
  constructor(http, baseURL) {
    this.http = http;
    this.baseURL = baseURL;
  }

  async getSalesList() {
    const results = await this.http.get(
      `${this.baseURL}/DashboardAPIJTest.php?cmd=getSalesList`
    );
    return results.data;
  }

  async getTotalsSales(apiName, dateStart, dateEnd, filter, granularity) {
    const results = await this.http.get(
      `${this.baseURL}/DashboardAPIJ.php?cmd=gettotalsSales&ApiName=${apiName}&DateStart=${dateStart}&DateEnd=${dateEnd}&Filter=${filter}&Granularity=${granularity}`
    );
    return results.data;
  }
}
